import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import {environment} from './../../../environments/environment';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {AppConfig} from './../../../app/app.config';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {ArrayHelper} from './../../_helpers/array.helper';
import {UserService} from './../../_services/user.service';
import {from} from 'rxjs';
import Swal from 'sweetalert2';
import {SweetAlertHelper} from '../../_helpers/sweet.alert.helper';
import {Message} from '../../_locale/messages';

@Component({
  selector: 'app-demographicsform',
  templateUrl: './demographicsform.component.html',
  styleUrls: ['./demographicsform.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class DemographicsformComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  loggedInUserId: string;
  userId: string;
  guardianDetails: string;
  demographicId: string;
  resultJsonObj: any = {};
  resultJsonObjCity: any = {};
  resultJsonObjPryPhyCity: any = {};
  resultJsonObjOrdPhyCity: any = {};
  existingDemographicData = false;
  newDemographicData = false;
  editDdemographicsformDataObj: any = {};
  calculatedAge: number;
  loggedUserEmail: any;
  loggedUserMobile: any;
  newAge: any;
  ahsDisclaimerPdf: SafeResourceUrl;
  ahsHippaPdf: SafeResourceUrl;
  showAddressSuggestionsModal = false;
  arrayGenders: any = ArrayHelper.GENDER_ARRAY;
  arrayMonths: any = ArrayHelper.MONTH_ARRAY;
  arrayDays: any = ArrayHelper.DAY_ARRAY;
  arrayYears: any = ArrayHelper.YEAR_ARRAY;
  arrayStates: any = ArrayHelper.STATE_ARRAY;
  statesArrayDB: any = [];
  addressLength = false;
  noAddressLength = false;
  addressList: any;
  cityLength = false;
  noCityLength = false;
  citiesList: any = [];
  pryPhyCityLength = false;
  noPryPhyCityLength = false;
  pryPhyCitiesList: any = [];
  ordPhyCityLength = false;
  noOrdPhyCityLength = false;
  ordPhyCitiesList: any = [];
  orderStateSelectDisabledOption = true;
  orderCitySelectDisabledOption = true;
  orderSelectDisabledOption = true;
  priStateSelectDisabledOption = true;
  priCitySelectDisabledOption = true;
  priSelectDisabledOption = true;
  stateSelectDisabledOption = true;
  citySelectDisabledOption = true;
  priPhysicianLength = false;
  noPriPhysicianLength = false;
  priPhysiciansList: any = [];
  ordPhysicianLength = false;
  noOrdPhysicianLength = false;
  ordPhysiciansList: any = [];
  searchStateList: any = ArrayHelper.STATE_ARRAY;
  showSearchPhysicianModal = false;
  showDependantDetailsModal = false;
  physicianSearchObj: any = {};
  guradianSearchObj: any = {};
  selectedPhysician: any = {};
  selectedParent: any = {};
  doctorTableArr: any = [];
  guardianTableArr: any = [];
  doctorTableArrTemp: any = [];
  guardianTableArrTemp: any = [];
  isPrimary: any;
  showLoader = false;
  onBehalfOfAlertDemographic = 'Y';
  isMinor = 0;
  isDependant = 0;
  lastDependantStatus;
  linkedUserIds: string[] = [];
  isChecked = false;
  message = Message;
  valueChanged = 0;
  guardianSearchCounter = 0;
  parentId = '';
  linkedGuardians: number[] = [];
  guardianId: any;
  physicianCount = 1;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService
  ) {
    this.ahsDisclaimerPdf = this.sanitizer.bypassSecurityTrustResourceUrl(this.appConfig.getDisclaimerPdf());
    this.ahsHippaPdf = this.sanitizer.bypassSecurityTrustResourceUrl(this.appConfig.getHippaPdf());
    this.loggedInUserId = localStorage.getItem('delegateId') ? localStorage.getItem('delegateId') : this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    // this.demographicId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    // this.activatedRoute.snapshot.paramMap.get('demographicId');

    // console.log('loggedInUserId:', this.loggedInUserId);
    // console.log('demographicId:', this.demographicId);

    this.editDdemographicsformDataObj.dobMonth = '';
    this.editDdemographicsformDataObj.dobDay = '';
    this.editDdemographicsformDataObj.dobYear = '';
    this.editDdemographicsformDataObj.gender = '';
    this.editDdemographicsformDataObj.isDependant = 0;
    this.editDdemographicsformDataObj.state = '';
    this.editDdemographicsformDataObj.city = '';
    this.editDdemographicsformDataObj.primaryPhysicianState = '';
    this.editDdemographicsformDataObj.primaryPhysicianCity = '';
    this.editDdemographicsformDataObj.primaryPhysician = '';
  }

  async ngAfterViewInit() {
    var item = localStorage.getItem('FullName');
    if (
      this.onBehalfOfAlertDemographic === 'Y' &&
      localStorage.getItem('isDelegating') === '1'
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.ngAfterViewInit + ' ' + item
      );
      localStorage.setItem('onBehalfOfAlertDemographic', 'N');
    }
  }

  ngOnInit() {
    // this.editDdemographicsformDataObj.isDependant = 0;

    this.fnFindDemographic();
    this.fnFindPhysicianCities();
    this.findUserDetails(this.loggedInUserId);
    this.onBehalfOfAlertDemographic = localStorage.getItem('onBehalfOfAlertDemographic') ? localStorage.getItem('onBehalfOfAlertDemographic') : 'Y';
    window.scrollTo(0, 0);
  }


  findUserDetails(userId) {
    const finalData = {
      company_slug: null,
      id: userId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.isMinor = apiResponse.commandResult.data.userInfo.isMinor;
            localStorage.setItem('isMinor', String(this.isMinor));
            localStorage.setItem('isDependant', String(apiResponse.commandResult.data.userInfo.IsDependant));
            this.lastDependantStatus = apiResponse.commandResult.data.userInfo.IsDependant;
            this.editDdemographicsformDataObj.isDependant = apiResponse.commandResult.data.userInfo.IsDependant;

            this.editDdemographicsformDataObj.fName = apiResponse.commandResult.data.userInfo.FirstName;
            this.editDdemographicsformDataObj.dobDay = apiResponse.commandResult.data.userInfo.DOB_Date;
            this.editDdemographicsformDataObj.dobMonth = apiResponse.commandResult.data.userInfo.DOB_Month;
            this.editDdemographicsformDataObj.dobYear = apiResponse.commandResult.data.userInfo.DOB_Year;
            this.editDdemographicsformDataObj.age = apiResponse.commandResult.data.userInfo.AgeOnRegistration;
            this.editDdemographicsformDataObj.primaryGuardianEmail = apiResponse.commandResult.data.guardianInfo.Email;
            this.editDdemographicsformDataObj.primaryGuardianName = apiResponse.commandResult.data.guardianInfo.FullName;
            this.guardianId = apiResponse.commandResult.data.guardianInfo.ParentId;
            if (
              apiResponse.commandResult.data.userInfo.MiddleName !== 'NA' && apiResponse.commandResult.data.userInfo.MiddleName !== 'N/A'
            ) {
              this.editDdemographicsformDataObj.mName = apiResponse.commandResult.data.userInfo.MiddleName;
            } else {
              this.editDdemographicsformDataObj.mName = '';
            }

            if (
              apiResponse.commandResult.data.userInfo.ParentFirstName !== 'NA' && apiResponse.commandResult.data.userInfo.ParentFirstName !== 'N/A'
            ) {
              this.editDdemographicsformDataObj.parentFirstName = apiResponse.commandResult.data.userInfo.ParentFirstName;
            } else {
              this.editDdemographicsformDataObj.parentFirstName = '';
            }

            if (
              apiResponse.commandResult.data.userInfo.ParentLastName !== 'NA' && apiResponse.commandResult.data.userInfo.ParentLastName !== 'N/A'
            ) {
              this.editDdemographicsformDataObj.parentLastName = apiResponse.commandResult.data.userInfo.ParentLastName;
            } else {
              this.editDdemographicsformDataObj.parentLastName = '';
            }
            this.editDdemographicsformDataObj.lName = apiResponse.commandResult.data.userInfo.LastName;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindDemographic() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
        ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log('demographic response:', apiResponse);
          this.editDdemographicsformDataObj.email = apiResponse.commandResult.data.userInfo.Email;
          this.editDdemographicsformDataObj.cellPhone = apiResponse.commandResult.data.userInfo.Mobile;
          this.editDdemographicsformDataObj.isDependant = apiResponse.commandResult.data.userInfo.IsDependant;

          if (apiResponse.commandResult.data.userInfo.DemographicId > 0) {
            const form1DOB = apiResponse.commandResult.data.userInfo.form1DOB.split('-');
            this.existingDemographicData = true;
            this.editDdemographicsformDataObj.fName = apiResponse.commandResult.data.userInfo.from1FirstName;
            if (
              apiResponse.commandResult.data.userInfo.from1MiddleName == 'NA' || apiResponse.commandResult.data.userInfo.from1MiddleName == '-'
            ) {
              this.editDdemographicsformDataObj.mName = '';
            } else {
              this.editDdemographicsformDataObj.mName = apiResponse.commandResult.data.userInfo.from1MiddleName;
            }
            this.editDdemographicsformDataObj.lName = apiResponse.commandResult.data.userInfo.from1LastName;
            this.editDdemographicsformDataObj.dobYear = form1DOB[0];
            this.editDdemographicsformDataObj.dobMonth = form1DOB[1];
            this.editDdemographicsformDataObj.dobDay = form1DOB[2];
            this.editDdemographicsformDataObj.age =
              apiResponse.commandResult.data.userInfo.form1Age;
            this.editDdemographicsformDataObj.gender = apiResponse.commandResult.data.userInfo.form1Gender;
            if (
              apiResponse.commandResult.data.userInfo.form1ResidenceNumber ==
              'NA' ||
              apiResponse.commandResult.data.userInfo.form1ResidenceNumber ==
              '-'
            ) {
              this.editDdemographicsformDataObj.residence = '';
            } else {
              this.editDdemographicsformDataObj.residence =
                apiResponse.commandResult.data.userInfo.form1ResidenceNumber;
            }
            this.editDdemographicsformDataObj.street =
              apiResponse.commandResult.data.userInfo.form1StreetName;
            this.editDdemographicsformDataObj.city =
              apiResponse.commandResult.data.userInfo.form1City;
            this.editDdemographicsformDataObj.state =
              apiResponse.commandResult.data.userInfo.form1State;
            this.editDdemographicsformDataObj.zip =
              apiResponse.commandResult.data.userInfo.form1ZIP;
            this.editDdemographicsformDataObj.email =
              apiResponse.commandResult.data.userInfo.form1EmailAddress;
            this.editDdemographicsformDataObj.cellPhone =
              apiResponse.commandResult.data.userInfo.form1CellularPhone;
            if (
              apiResponse.commandResult.data.userInfo.form1CellularPhoneAlt ==
              'NA' ||
              apiResponse.commandResult.data.userInfo.form1CellularPhoneAlt ==
              '-'
            ) {
              this.editDdemographicsformDataObj.altPhone = '';
            } else {
              this.editDdemographicsformDataObj.altPhone =
                apiResponse.commandResult.data.userInfo.form1CellularPhoneAlt;
            }
            this.editDdemographicsformDataObj.demographicId =
              apiResponse.commandResult.data.userInfo.DemographicId;

            this.editDdemographicsformDataObj.primaryPhysician =
              this.userService.getUS_FromatName(
                apiResponse.commandResult.data.userInfo.form1PrimaryPhysician
              );
            this.editDdemographicsformDataObj.primaryPhysicianState =
              apiResponse.commandResult.data.userInfo.form1PrimaryPhysicianState;
            this.editDdemographicsformDataObj.primaryPhysicianStateName =
              this.arrayStates.find(
                (e) =>
                  e.StateCode ==
                  apiResponse.commandResult.data.userInfo
                    .form1PrimaryPhysicianState
              ).State;
            this.editDdemographicsformDataObj.primaryPhysicianCity =
              apiResponse.commandResult.data.userInfo.form1PrimaryPhysicianCity;
          } else {
            this.newDemographicData = true;
            this.editDdemographicsformDataObj.demographicId = 0;
            this.existingDemographicData = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindCitiesList(stateCode) {
    const finalDataCity = {stateCode};
    this.http
      .post(
        this.appConfig.getLambdaUrl01() +
        ApiHelper.name_getCitiesByStateCodeApi,
        finalDataCity,
        this.httpOptions
      )
      .subscribe(
        (apiResponseCity) => {
          this.resultJsonObjCity = apiResponseCity;
          if (this.resultJsonObjCity.commandResult.status == 1) {
            if (this.resultJsonObjCity.commandResult.data.cityList.length > 0) {
              this.cityLength = true;
              this.citiesList =
                this.resultJsonObjCity.commandResult.data.cityList;
            } else {
              this.noCityLength = true;
            }
          } else {
            this.noCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindPrimaryPhysicianCitiesList(stateCode) {
    const finalDataPryPhyCity = {stateCode};
    this.http
      .post(
        this.appConfig.getLambdaUrl01() +
        ApiHelper.name_getCitiesByStateCodeApi,
        finalDataPryPhyCity,
        this.httpOptions
      )
      .subscribe(
        (apiResponsePryPhyCity) => {
          this.resultJsonObjPryPhyCity = apiResponsePryPhyCity;
          if (this.resultJsonObjPryPhyCity.commandResult.status == 1) {
            if (
              this.resultJsonObjPryPhyCity.commandResult.data.cityList.length >
              0
            ) {
              this.pryPhyCityLength = true;
              this.pryPhyCitiesList =
                this.resultJsonObjPryPhyCity.commandResult.data.cityList;
            } else {
              this.noPryPhyCityLength = true;
            }
          } else {
            this.noPryPhyCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindOrderingPhysicianCitiesList(stateCode) {
    const finalDataOrdPhyCity = {stateCode};
    this.http
      .post(
        this.appConfig.getLambdaUrl01() +
        ApiHelper.name_getCitiesByStateCodeApi,
        finalDataOrdPhyCity,
        this.httpOptions
      )
      .subscribe(
        (apiResponseOrdPhyCity) => {
          this.resultJsonObjOrdPhyCity = apiResponseOrdPhyCity;
          if (this.resultJsonObjOrdPhyCity.commandResult.status == 1) {
            if (
              this.resultJsonObjOrdPhyCity.commandResult.data.cityList.length >
              0
            ) {
              this.ordPhyCityLength = true;
              this.ordPhyCitiesList =
                this.resultJsonObjOrdPhyCity.commandResult.data.cityList;
            } else {
              this.noOrdPhyCityLength = true;
            }
          } else {
            this.noOrdPhyCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindPrimaryPhysicianList(cityName) {
    if (this.existingDemographicData) {
      const finalDataPriPhysician = {cityName};
      this.http
        .post(
          this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
          finalDataPriPhysician,
          this.httpOptions
        )
        .subscribe(
          (apiResponsePhysician: any) => {
            if (apiResponsePhysician.commandResult.status == 1) {
              if (
                apiResponsePhysician.commandResult.data.physiciansList.length >
                0
              ) {
                this.priPhysicianLength = true;
                this.priSelectDisabledOption = false;
                this.priPhysiciansList = this.fnChangePhysicianNameFormat(
                  apiResponsePhysician.commandResult.data.physiciansList
                );
              } else {
                this.noPriPhysicianLength = true;
              }
            } else {
              this.noPriPhysicianLength = true;
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  fnFindOrderingPhysicianList(cityName) {
    if (this.existingDemographicData) {
      const finalDataPhysician = {cityName};
      this.http
        .post(
          this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
          finalDataPhysician,
          this.httpOptions
        )
        .subscribe(
          (apiResponsePhysician: any) => {
            if (apiResponsePhysician.commandResult.status == 1) {
              if (
                apiResponsePhysician.commandResult.data.physiciansList.length >
                0
              ) {
                this.ordPhysicianLength = true;
                this.orderSelectDisabledOption = false;
                this.ordPhysiciansList = this.fnChangePhysicianNameFormat(
                  apiResponsePhysician.commandResult.data.physiciansList
                );
              } else {
                this.noOrdPhysicianLength = true;
              }
            } else {
              this.noOrdPhysicianLength = true;
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  fnFindPhysicianCities() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findPhysicianCitiesApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.statesArrayDB = apiResponse.commandResult.data.statesList;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  onSubmit1(f) {
    if (!f.dirty) {
      Swal.fire({
        title: 'PLEASE CONFIRM',
        text: 'Click \'OK\' to confirm you\'ve reviewed your demographic profile and your intent not to make any changes.',
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.value) {
          this.router.navigate([
            '/dashboard/' +
            this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
            '/1',
          ]);
        }
      });
    } else {
      Swal.fire({
        title: 'PLEASE CONFIRM',
        text: 'Click \'OK\' to finalize the demographic changes you have made. You have selected to change certain demographic sections that will require you resign and Updated AHS Disclaimer and HIPAA Forms.',
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.value) {
          const finalData = {
            fName: this.editDdemographicsformDataObj.fName,
            mName: this.editDdemographicsformDataObj.mName,
            lName: this.editDdemographicsformDataObj.lName,
            primaryGuardianName: this.editDdemographicsformDataObj.primaryGuardianName,
            primaryGuardianEmail: this.editDdemographicsformDataObj.primaryGuardianEmail,
            parentFirstName: this.editDdemographicsformDataObj.parentFirstName ? this.editDdemographicsformDataObj.parentFirstName : 'NA',
            parentLastName: this.editDdemographicsformDataObj.parentLastName ? this.editDdemographicsformDataObj.parentLastName : 'NA',
            dobMonth: this.editDdemographicsformDataObj.dobMonth,
            dobDay: this.editDdemographicsformDataObj.dobDay,
            dobYear: this.editDdemographicsformDataObj.dobYear,
            age: this.newAge ? this.newAge : this.editDdemographicsformDataObj.age,
            gender: this.editDdemographicsformDataObj.gender,
            isDependant: this.editDdemographicsformDataObj.isDependant ? this.editDdemographicsformDataObj.isDependant : this.isDependant,
            lastDependantStatus: this.lastDependantStatus,
            residence: this.editDdemographicsformDataObj.residence ? this.editDdemographicsformDataObj.residence : 'NA',
            street: this.editDdemographicsformDataObj.street,
            city: this.editDdemographicsformDataObj.city,
            state: this.editDdemographicsformDataObj.state,
            zip: this.editDdemographicsformDataObj.zip,
            email: this.editDdemographicsformDataObj.email,
            cellPhone: this.editDdemographicsformDataObj.cellPhone,
            altPhone: this.editDdemographicsformDataObj.altPhone,
            loggedInUserId: this.loggedInUserId,
            demographicId: this.editDdemographicsformDataObj.demographicId,
            primaryPhysician: this.userService.getRefromatName(this.editDdemographicsformDataObj.primaryPhysician),
            primaryPhysicianState: this.editDdemographicsformDataObj.primaryPhysicianState,
            primaryPhysicianCity: this.editDdemographicsformDataObj.primaryPhysicianCity,
            parentId: this.parentId,
          };


          let apiUrl = '';
          if (this.editDdemographicsformDataObj.demographicId > 0 || this.editDdemographicsformDataObj.demographicId === null) {
            apiUrl = ApiHelper.php_updateDemographicDetailsGeneralApi;
          } else {
            apiUrl = ApiHelper.php_saveDemographicFormApiGeneralApi;
          }

          this.showLoader = true;

          return this.http
            .post(
              this.appConfig.getPhpUrl() + apiUrl,
              finalData,
              this.httpOptions
            )
            .subscribe(
              async (apiResponse) => {
                this.showLoader = false;
                this.resultJsonObj = apiResponse;
                // console.log('valueChanged:', this.valueChanged);
                if (this.resultJsonObj.commandResult.status === 1) {
                  this.demographicId = this.resultJsonObj.commandResult.data.demographicInfo.Id;
                  if (this.valueChanged === 2) {
                    this.router.navigate(['/hippa-acceptance/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.demographicId
                    ]);
                  } else {
                    this.router.navigate(['/dashboard/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/1']);
                  }
                } else {
                  this.showLoader = false;
                  const alertStatus = await this.sweetAlertHelper.alertPopUp(
                    this.message.alertMessages.demographics.onSubmit
                  );
                  // alert("oops! error occurred, please try later");
                }
              },
              (err) => {
                // console.log(err);
              }
            );
        }
      });
    }
  }


  verifyAddress() {
    this.addressList = {};
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
    };

    const finalData = {
      streeAddress:
        this.editDdemographicsformDataObj.residence +
        ', ' +
        this.editDdemographicsformDataObj.street +
        ', ' +
        this.editDdemographicsformDataObj.city +
        ', ' +
        this.editDdemographicsformDataObj.state +
        ', ' +
        this.editDdemographicsformDataObj.zip,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_verifyAddressApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.status == 1) {
            if (this.resultJsonObj.commandResult.data.Addresses.length > 0) {
              this.addressLength = true;
              this.addressList =
                this.resultJsonObj.commandResult.data.Addresses;
            } else {
              //
            }
          } else {
            //
          }
          this.showAddressSuggestionsModal = true;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  closeAddressSuggestionsModal() {
    this.showAddressSuggestionsModal = false;
  }

  onItemChange(address) {
    if (address != 'curentAdd') {
      const splitted = address.split(',');
      this.editDdemographicsformDataObj.street = splitted[0];
      const stateName = splitted[1]
        .trim()
        .substring(splitted[1].trim().lastIndexOf(' ') + 1);
      const cityName = splitted[1]
        .trim()
        .substring(0, splitted[1].trim().lastIndexOf(' '));
      this.editDdemographicsformDataObj.city = cityName;
      this.editDdemographicsformDataObj.state = stateName;
      this.showAddressSuggestionsModal = false;
    }
  }

  findCitiesByStateCode(stateCode) {
    this.stateSelectDisabledOption = false;
    this.citiesList = [];
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {stateCode};
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
        ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          this.citySelectDisabledOption = true;
          this.editDdemographicsformDataObj.zip = null;
          if (this.resultJsonObj.commandResult.status === 1) {
            if (this.resultJsonObj.commandResult.data.cityList.length > 0) {
              this.cityLength = true;
              this.citiesList = this.resultJsonObj.commandResult.data.cityList;
            } else {
              this.noCityLength = true;
            }
          } else {
            this.noCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPryPhyCitiesByStateCode(stateCode) {
    this.priStateSelectDisabledOption = false;

    this.pryPhyCitiesList = [];
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {stateCode};
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
        ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          this.editDdemographicsformDataObj.primaryPhysicianCity = '';
          this.editDdemographicsformDataObj.primaryPhysician = '';
          this.priCitySelectDisabledOption = false;
          if (this.resultJsonObj.commandResult.status === 1) {
            if (this.resultJsonObj.commandResult.data.cityList.length > 0) {
              this.pryPhyCityLength = true;
              this.pryPhyCitiesList =
                this.resultJsonObj.commandResult.data.cityList;
            } else {
              this.noPryPhyCityLength = true;
            }
          } else {
            this.noPryPhyCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findOrdPhyCitiesByStateCode(stateCode) {
    this.orderStateSelectDisabledOption = false;

    this.ordPhyCitiesList = [];
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {stateCode};
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
        ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          this.orderCitySelectDisabledOption = false;

          this.editDdemographicsformDataObj.orderingPhysicianCity = '';
          this.editDdemographicsformDataObj.orderingPhysician = '';

          if (this.resultJsonObj.commandResult.status === 1) {
            if (this.resultJsonObj.commandResult.data.cityList.length > 0) {
              this.ordPhyCityLength = true;
              this.ordPhyCitiesList =
                this.resultJsonObj.commandResult.data.cityList;
            } else {
              this.noOrdPhyCityLength = true;
            }
          } else {
            this.noOrdPhyCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onReset() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.demographics.onReset
    );

    if (alertStatus) {
      this.router.navigate([
        '/dashboard/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/1',
      ]);
    }
    // if
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘Cancel’ to revert to original and return to the ‘Home Page’."
    //   )
    // ) {
    //   this.router.navigate([
    //     "/dashboard/" +
    //       this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
    //       "/1",
    //   ]);
    // }
  }

  hideDisabledOrderCity() {
    this.orderCitySelectDisabledOption = false;
  }

  hideDisabledOrder() {
    this.orderSelectDisabledOption = false;
  }

  hideDisabledPriCity() {
    this.priCitySelectDisabledOption = false;
  }

  hideDisabledPri() {
    this.priSelectDisabledOption = false;
  }

  hideDisabledCity() {
    this.citySelectDisabledOption = false;
  }

  findPryPhysiciansByCity(cityName) {
    this.priCitySelectDisabledOption = false;
    const finalDataPhysician = {cityName};
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
        ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          if (apiResponsePhysician.commandResult.status === 1) {
            this.priSelectDisabledOption = false;
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.priPhysicianLength = true;
              // this.priPhysiciansList = apiResponsePhysician.commandResult.data.physiciansList;
              this.priPhysiciansList = this.fnChangePhysicianNameFormat(
                apiResponsePhysician.commandResult.data.physiciansList
              );
            } else {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.demographics.findPryPhysiciansByCity
              );

              // alert("No Physicians Found");

              this.noPriPhysicianLength = true;
            }
          } else {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.findPryPhysiciansByCity
            );
            this.noPriPhysicianLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findOdrPhysiciansByCity(cityName) {
    this.orderCitySelectDisabledOption = false;
    const finalDataOrdPhysician = {cityName};
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
        ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataOrdPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          if (apiResponsePhysician.commandResult.status === 1) {
            this.orderSelectDisabledOption = false;
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.ordPhysicianLength = true;
              this.ordPhysiciansList = this.fnChangePhysicianNameFormat(
                apiResponsePhysician.commandResult.data.physiciansList
              );
            } else {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.demographics.findPryPhysiciansByCity
              );
              // alert("No Physicians Found");
              this.noOrdPhysicianLength = true;
            }
          } else {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.findPryPhysiciansByCity
            );
            // alert("No Physicians Found");
            this.noOrdPhysicianLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }

  calculateAge(value) {
    if (value.dobDay > 0 && value.dobMonth > 0 && value.dobYear > 0) {
      const calculatedAge = this.commonHelper.calculateAge(
        value.dobYear,
        value.dobMonth,
        value.dobDay
      );
      this.editDdemographicsformDataObj.age = calculatedAge;
      this.newAge = calculatedAge;
    } else {
      this.editDdemographicsformDataObj.age =
        this.editDdemographicsformDataObj.age;
    }
  }

  async goToDashboad() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        '/dashboard/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
      ]);
    }
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // ) {
    //   this.router.navigate([
    //     "/dashboard/" +
    //       this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
    //   ]);
    // }
  }

  fnChangePhysicianNameFormat(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.PhysicianName = this.userService.getUS_FromatName(e.PhysicianName);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(
      this.userService.dynamicSort('PhysicianName')
    );
  }

  openSearchPhysicianModal(isPrimary) {
    this.isPrimary = isPrimary;
    this.showSearchPhysicianModal = true;
    this.physicianSearchObj.physicianSearchLastName = '';
    this.physicianSearchObj.physicianSearchFirstName = '';

    this.physicianSearchObj.physicianSearchState = '';
    this.physicianSearchObj.physicianSearchNPI = '';
    this.doctorTableArr = [];
  }

  openDependantDetailsModal(isPrimary) {
    this.isPrimary = isPrimary;
    this.showDependantDetailsModal = true;
    this.guradianSearchObj.firstName = '';
    this.guradianSearchObj.lastName = '';
    this.guradianSearchObj.email = '';
    this.guardianTableArr = [];
  }

  resetSearchPhysicianModal() {
    this.isPrimary = '';
    this.physicianSearchObj.physicianSearchLastName = '';
    this.physicianSearchObj.physicianSearchFirstName = '';

    this.physicianSearchObj.physicianSearchState = '';
    this.physicianSearchObj.physicianSearchNPI = '';
    this.doctorTableArr = [];
    this.physicianCount = 1;
  }

  resetDependantDetailsModal() {
    this.isPrimary = '';
    this.guradianSearchObj.firstName = '';
    this.guradianSearchObj.lastName = '';
    this.guradianSearchObj.email = '';
    this.guardianTableArr = [];
  }

  closeSearchPhysicianModal() {
    this.isPrimary = '';
    this.showSearchPhysicianModal = false;
    this.physicianSearchObj.physicianSearchLastName = '';
    this.physicianSearchObj.physicianSearchFirstName = '';

    this.physicianSearchObj.physicianSearchState = '';
    this.physicianSearchObj.physicianSearchNPI = '';
    this.doctorTableArr = [];
  }

  closeGuardianSearchModal() {
    this.isPrimary = '';
    this.showDependantDetailsModal = false;
    this.guradianSearchObj.firstName = '';
    this.guradianSearchObj.lastName = '';
    this.guradianSearchObj.email = '';
    this.guardianTableArr = [];
    if (this.editDdemographicsformDataObj.isDependant == 0) {
      this.editDdemographicsformDataObj.isDependant = 1;
    } else {
      this.editDdemographicsformDataObj.isDependant = 0;
    }
  }

  async searchGuardian() {
    if (!this.guradianSearchObj.email && !this.guradianSearchObj.firstName && !this.guradianSearchObj.lastName) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.demographics.searchGuardianDetails);
      return;
    } else if (this.guradianSearchObj.lastName && !this.guradianSearchObj.firstName && !this.guradianSearchObj.email) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.demographics.searchGuardianFirstName);
      return;
    } else if (this.guradianSearchObj.firstName && !this.guradianSearchObj.lastName && !this.guradianSearchObj.email) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.demographics.searchGuardianLastName);
      return;
    } else if (this.guradianSearchObj.firstName && this.guradianSearchObj.lastName && !this.guradianSearchObj.email) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.demographics.searchGuardianEmail);
      return;
    } else if (this.guradianSearchObj.email && !this.guradianSearchObj.firstName && !this.guradianSearchObj.lastName) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.demographics.searchGuardianfnamelname);
      return;
    } else {
      const loggedUserEmail = localStorage.getItem('loggedInUserEmail');
      const isSearchingSelf = this.guradianSearchObj.email && this.guradianSearchObj.email.toLowerCase() === loggedUserEmail.toLowerCase();
      this.guardianTableArr = [];
      this.showLoader = true;
      const finalData = {
        userId: this.loggedInUserId,
        firstName: this.guradianSearchObj.firstName,
        lastName: this.guradianSearchObj.lastName,
        email: this.guradianSearchObj.email,
      };
      this.http
        .post(
          this.appConfig.getLambdaUrl05() + ApiHelper.searchParents,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            if (isSearchingSelf) {
              this.showLoader = false;
              const alertStatus = await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.demographics.selfSearchAsGuradian);
              if (alertStatus) {
                this.resetDependantDetailsModal();
              }
              return;
            }
            this.guardianTableArr = apiResponse.commandResult.data.minors;
            this.showLoader = false;
            if (this.guardianTableArr.length == 0) {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.demographics.SearchGuardianNoRecords);
              if (alertStatus) {
                this.resetDependantDetailsModal();
              }
              // Display alert message
              this.guardianSearchCounter++;
            }
            if (this.guardianSearchCounter == 3) {
              // console.log('alertStstus:', this.message.alertMessages.demographics.searchCounter);
              const alertStatus = await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.demographics.searchCounter);
              if (alertStatus) {
                this.router.navigate([
                  '/dashboard/' +
                  this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
                ]);
              }
            }
            for (const guardian of this.guardianTableArr) {
              const isDependant = localStorage.getItem('isDependant');
              if (isDependant == '1' && this.guardianId !== guardian.RecordId) {
                const alertStatus = await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.demographics.searchValidGuardian);
                return this.resetDependantDetailsModal();
              } else {
                // console.log(this.guardianId, guardian.RecordId);
              }
            }
            this.editDdemographicsformDataObj.primaryGuardianEmail = apiResponse.commandResult.data.minors.Email;
            this.editDdemographicsformDataObj.primaryGuardianName = apiResponse.commandResult.data.minors.FullName;
            this.showLoader = false;
          },
          (err) => {
          }
        );
    }
  }


  async searchPhysicians() {
    if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchFirstName
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchPhysicians
      );

      // alert(
      //   "Please enter first name. You may search physician informantion by NPI number."
      // );
      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchLastName
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchPhysician2
      );

      // alert(
      //   "Please enter last name. You may search physician informantion by NPI number."
      // );
      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchState
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchPhysicians1
      );

      // alert("Please select state.");
      return;
    } else {
      this.doctorTableArr = [];
      this.showLoader = true;
      const finalData = {
        loggedInUserId: this.loggedInUserId,
        physicianSearchFirstName:
        this.physicianSearchObj.physicianSearchFirstName,
        physicianSearchLastName:
        this.physicianSearchObj.physicianSearchLastName,
        physicianSearchState: this.physicianSearchObj.physicianSearchState,
        physicianSearchNPI: this.physicianSearchObj.physicianSearchNPI,
      };
      this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.searchNPIRegistry,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            this.doctorTableArrTemp =
              apiResponse.commandResult.data.physiciansList;
            this.doctorTableArr = apiResponse.commandResult.data.physiciansList;
            this.physicianCount = apiResponse.commandResult.data.physiciansCout;
            this.showLoader = false;
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  selectGuardian(userId) {
    // console.log('userId:', userId);
    this.parentId = userId;
    // console.log('Parent id0', this.parentId);
    this.selectedParent = this.guardianTableArr.find((e) => e.RecordId === userId);
    // console.log('selectedParent:', this.selectedParent);
    this.showDependantDetailsModal = false;
    this.editDdemographicsformDataObj.primaryGuardianEmail = this.selectedParent.Email;
    this.editDdemographicsformDataObj.primaryGuardianName = this.selectedParent.FullName;
    // if(this.isPrimary === 1){
    // }
    if (this.isLinked(userId)) {
      this.unlinkGuardian(userId);
    } else {
      this.linkGuardian(userId);
    }
  }

  isLinked(userId: number): boolean {
    return this.linkedGuardians.includes(userId);
  }

  linkGuardian(userId: number): void {
    this.linkedGuardians.push(userId);
  }

  unlinkGuardian(userId: number): void {
    const index = this.linkedGuardians.indexOf(userId);
    if (index !== -1) {
      this.linkedGuardians.splice(index, 1);
    }
  }

  showAlert(): void {
    alert('This user is not linked.');
  }

  selectDoctor(physicianId) {
    this.selectedPhysician = this.doctorTableArr.find(
      (e) => e.RecordId === physicianId
    );
    // console.log(this.isPrimary);
    this.showSearchPhysicianModal = false;
    this.updateValueChanged(1);
    if (this.isPrimary === 1) {
      this.editDdemographicsformDataObj.primaryPhysicianState =
        this.selectedPhysician.PhysicianStateCode;
      this.editDdemographicsformDataObj.primaryPhysicianStateName =
        this.selectedPhysician.PhysicianStateName;
      this.editDdemographicsformDataObj.primaryPhysicianCity =
        this.selectedPhysician.PhysicianCity;
      this.editDdemographicsformDataObj.primaryPhysician =
        this.selectedPhysician.PhysicianName;
      // console.log(this.editDdemographicsformDataObj.primaryPhysicianState);
      // console.log(this.editDdemographicsformDataObj.primaryPhysicianStateName);
    } else {
      this.editDdemographicsformDataObj.orderingPhysicianState =
        this.selectedPhysician.PhysicianStateCode;
      this.editDdemographicsformDataObj.orderingPhysicianStateName =
        this.selectedPhysician.PhysicianStateName;
      this.editDdemographicsformDataObj.orderingPhysicianCity =
        this.selectedPhysician.PhysicianCity;
      this.editDdemographicsformDataObj.orderingPhysician =
        this.selectedPhysician.PhysicianName;
    }
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  setStateCode(stateCode) {
    this.physicianSearchObj.physicianSearchState = stateCode;
  }


  async checkAllHippaDoc(isChecked) {
    if (isChecked == 1) {

    } else {


    }

  }


  updateValueChanged(value) {
    // console.log('Start value', this.valueChanged);
    if (this.valueChanged == 0) {
      this.valueChanged = value;
    } else if (this.valueChanged == 1) {
      this.valueChanged = value;
    } else {
      this.valueChanged = 2;
    }
    // console.log('Changed value', this.valueChanged);
  }

  async onSubmit(f) {
    // alert('demographicId=>'+this.editDdemographicsformDataObj.demographicId);
    // alert('valueChanged=>'+this.valueChanged);
    if (this.valueChanged == 0 && !f.dirty) {
      Swal.fire({
        title: 'PLEASE CONFIRM',
        text: 'Click \'OK\' to confirm you\'ve reviewed your demographic profile and your intent not to make any changes.',
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.value) {
          this.router.navigate(['/dashboard/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/1']);
        }
      });
    } else {
      // alert('valueChanged=>'+ this.valueChanged);
      const finalData = {
        fName: this.editDdemographicsformDataObj.fName,
        mName: this.editDdemographicsformDataObj.mName,
        lName: this.editDdemographicsformDataObj.lName,
        parentFirstName: this.editDdemographicsformDataObj.parentFirstName ? this.editDdemographicsformDataObj.parentFirstName : 'NA',
        parentLastName: this.editDdemographicsformDataObj.parentLastName ? this.editDdemographicsformDataObj.parentLastName : 'NA',
        dobMonth: this.editDdemographicsformDataObj.dobMonth,
        dobDay: this.editDdemographicsformDataObj.dobDay,
        dobYear: this.editDdemographicsformDataObj.dobYear,
        age: this.newAge ? this.newAge : this.editDdemographicsformDataObj.age,
        gender: this.editDdemographicsformDataObj.gender,
        isDependant: this.editDdemographicsformDataObj.isDependant ? this.editDdemographicsformDataObj.isDependant : this.isDependant,
        lastDependantStatus: this.lastDependantStatus,
        residence: this.editDdemographicsformDataObj.residence ? this.editDdemographicsformDataObj.residence : 'NA',
        street: this.editDdemographicsformDataObj.street,
        city: this.editDdemographicsformDataObj.city,
        state: this.editDdemographicsformDataObj.state,
        zip: this.editDdemographicsformDataObj.zip,
        email: this.editDdemographicsformDataObj.email,
        cellPhone: this.editDdemographicsformDataObj.cellPhone,
        altPhone: this.editDdemographicsformDataObj.altPhone,
        loggedInUserId: this.loggedInUserId,
        demographicId: this.editDdemographicsformDataObj.demographicId,
        primaryPhysician: this.userService.getRefromatName(this.editDdemographicsformDataObj.primaryPhysician),
        primaryPhysicianState: this.editDdemographicsformDataObj.primaryPhysicianState,
        primaryPhysicianCity: this.editDdemographicsformDataObj.primaryPhysicianCity,
        parentId: this.parentId,
      };

      let apiUrl = '';
      if (this.editDdemographicsformDataObj.demographicId > 0) {
        apiUrl = ApiHelper.php_updateDemographicDetailsGeneralApi;
      } else {
        apiUrl = ApiHelper.php_saveDemographicFormApiGeneralApi;
      }
      // alert('apiUrl=>'+ apiUrl);
      // alert('demographicId=>'+ finalData.demographicId);
      // alert('valueChanged=>'+this.valueChanged);
      if (this.valueChanged === 2) {
        // alert('valueChanged2=>'+ this.valueChanged);
        this.showLoader = false;
        Swal.fire({
          title: 'PLEASE CONFIRM',
          text: 'Are you sure you want to update this profile? Doing so will require you to resign the AHS HIPAA Form, waiver, and disclaimer.',
          showCancelButton: true,
          confirmButtonText: 'OK'
        }).then((result) => {
          this.showLoader = true;
          if (result.value) {
            return this.http.post(this.appConfig.getPhpUrl() + apiUrl, finalData, this.httpOptions).subscribe(
              async (apiResponse) => {
                this.resultJsonObj = apiResponse;
                this.showLoader = false;

                if (this.resultJsonObj.commandResult.status === 1) {
                  this.demographicId = this.resultJsonObj.commandResult.data.demographicInfo.Id;
                  this.router.navigate(['/hippa-acceptance/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.demographicId]);
                } else {
                  this.showLoader = false;
                  const alertStatus = await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.demographics.onSubmit);
                  // alert("oops! error occurred, please try later");
                }
              }, (err) => {
                // console.log(err);
              }
            );
          } else {
            window.location.reload();
          }
        });
      } else {
        // alert('valueChanged1=>'+ this.valueChanged);

        const alertStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.demographicsForm.onsubmit);
        if (alertStatus) {

          // alert('this.existingDemographicData'+ this.existingDemographicData);
          if (this.valueChanged == 0 && this.existingDemographicData) {
            this.router.navigate(['/dashboard/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/1']);
          } else {
            this.showLoader = true;

            return this.http.post(this.appConfig.getPhpUrl() + apiUrl, finalData, this.httpOptions).subscribe(
              async (apiResponse) => {
                this.resultJsonObj = apiResponse;
                if (this.resultJsonObj.commandResult.status === 1) {
                  this.showLoader = false;

                  this.demographicId = this.resultJsonObj.commandResult.data.demographicInfo.Id;
                  // this.router.navigate(['/hippa-acceptance/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.demographicId]);
                  this.router.navigate(['/dashboard/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/1']);
                } else {
                  this.showLoader = false;
                  await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.demographics.onSubmit);
                }
              }, (err) => {
                // console.log(err);
              });
          }
        }
      }
    }
  }


  clearSearchPhysicianInputs(flag) {
    if (flag) {
      this.physicianSearchObj.physicianSearchFirstName = '';
      this.physicianSearchObj.physicianSearchLastName = '';
      this.physicianSearchObj.physicianSearchState = '';
    } else {
      this.physicianSearchObj.physicianSearchNPI = '';
    }
  }
// Test it
}
