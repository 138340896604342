import { from } from "rxjs";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { IDropdownSettings } from "ng-multiselect-dropdown";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { UserService } from "./../../_services/user.service";
import { AppConfig } from "./../../../app/app.config";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";

import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";
import { versions } from "process";
import { compilePipeFromMetadata } from "@angular/compiler";

declare let $: any;

@Component({
  selector: "app-upload-multiple-documents",
  templateUrl: "./upload-multiple-documents.component.html",
  styleUrls: ["./upload-multiple-documents.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class UploadMultipleDocumentsComponent implements OnInit {
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private uploadService: UploadService,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService
  ) {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get("demographicId");
    this.docInfo.doc = "";
  }

  @ViewChild("fileUpoladed", { static: false }) fileUpoladed: ElementRef;
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  fileToUpload: File = null;
  fileName: string;
  companySlug = "";
  previousDocId = "";
  docType = ConstantHelper.DOC_TYPE_USER_UPLOADED;
  companiesList: any = [];
  companiesListLength = false;

  attachmentDocment: any = "";
  files: any = [];
  practices: any;
  uploadedPracticsSettings: any;
  uploadedPracticesListSelected: any = [];
  uploadedPracticesListSelected0: any = [];
  uploadedPracticesListSelected1: any = [];
  uploadedPracticesListSelected2: any = [];
  uploadedPracticesListSelected3: any = [];
  uploadedPracticesListSelected4: any = [];

  physicans: any;

  uploadedPhysicanSettings: any;
  uploadedPhysicanListSelected: any = [];
  uploadedPhysicanListSelected0: any = [];
  uploadedPhysicanListSelected1: any = [];
  uploadedPhysicanListSelected2: any = [];
  uploadedPhysicanListSelected3: any = [];
  uploadedPhysicanListSelected4: any = [];

  uploadedDocumentsList: any = [];
  uploadedDocumentsListSelected: any = [];
  uploadedDropdownSettings: any = {};

  s3Files: any = [];
  isUploadFile = false;

  ppData = [
    {
      practices: [],
      physicans: [],
    },
    {
      practices: [],
      physicans: [],
    },
    {
      practices: [],
      physicans: [],
    },
    {
      practices: [],
      physicans: [],
    },
    {
      practices: [],
      physicans: [],
    },
  ];

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  docInfo: any = {};

  isFileSelected = false;
  @ViewChild("fileLog", { static: false }) fileLog: ElementRef;

  tempPractice01: any = [];
  tempPractice02: any = [];
  tempPractice03: any = [];
  tempPractice04: any = [];
  tempPractice05: any = [];

  tempPhysician01: any = [];
  tempPhysician02: any = [];
  tempPhysician03: any = [];
  tempPhysician04: any = [];
  tempPhysician05: any = [];

  finalPhysician01: any = [];
  finalPhysician02: any = [];
  finalPhysician03: any = [];
  finalPhysician04: any = [];
  finalPhysician05: any = [];

  show01 = false;
  show02 = false;
  show03 = false;
  show04 = false;
  show05 = false;
  practiceInfo1: any = "";
  practiceInfo2: any = "";
  practiceInfo3: any = "";
  practiceInfo4: any = "";
  practiceInfo5: any = "";

  physicianInfo1: any = "";
  physicianInfo2: any = "";
  physicianInfo3: any = "";
  physicianInfo4: any = "";
  physicianInfo5: any = "";

  physicans1: any;
  physicans2: any;
  physicans3: any;
  physicans4: any;
  physicans5: any;

  physicianLength1 = false;
  physicianLength2 = false;
  physicianLength3 = false;
  physicianLength4 = false;
  physicianLength5 = false;

  disabled1 = false;
  disabled2 = false;
  disabled3 = false;
  disabled4 = false;
  disabled5 = false;

  previou_permission1 = [];
  previou_permission2 = [];
  previou_permission3 = [];
  previou_permission4 = [];
  previou_permission5 = [];

  previou_practice_permission1 = [];
  previou_practice_permission2 = [];
  previou_practice_permission3 = [];
  previou_practice_permission4 = [];
  previou_practice_permission5 = [];

  choosedPhysicians: any = [];
  choosedPractices: any = [];

  isDisableSubmitBtn = true;
  selectedDoctorsSlug: any = [];
  selectedDoctorsId: any = [];
  isHippByAdmin = false;
  profileObj: any;

  isDisabled = false;

  finalRequest: any = { isUploadFile: 0 };

  ngOnInit() {
    $(function () {
      $("input[type=file]").change(function () {
        const t = $(this).val();
        const labelText = "File : " + t.substr(12, t.length);
        $(this).prev("label").text(labelText);
      });
    });
    this.findCompanies();
    this.findUserDetails(this.loggedInUserId);
    this.getPractices();
  }

  getPractices() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findUserDoctorsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.practices = apiResponse.commandResult.data.companiesList;
          // console.log('Practices list=>', this.practices);
          this.uploadedPracticsSettings = {
            singleSelection: false,
            idField: "company_url",
            textField: "company",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            itemsShowLimit: 3,
            allowSearchFilter: true,
          };

          // this.companiesListLength = true;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  getPhysican(company_url, i) {
    const reqData = { company_slug: company_url };

    // console.log('req data', reqData);
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findPhysiciansByCompanySlugApi,
        reqData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.ppData[i].physicans =
            apiResponse.commandResult.data.companiesList;
          // console.log('physicans list=>', this.ppData[i].physicans);
          // console.log('physicans=>', apiResponse);
          this.uploadedPhysicanSettings = {
            singleSelection: false,
            idField: "physician_id",
            textField: "physician",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            itemsShowLimit: 3,
            allowSearchFilter: true,
          };

          // this.companiesListLength = true;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findCompanies() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findUserRegisteredPhysiciansApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.companiesList = apiResponse.commandResult.data.companiesList;
          // console.log('companiesList=>', this.companiesList);
          this.companiesListLength = true;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onFileSelected(event) {
    this.files = [];
    // console.log(this.s3Files.length);
    if (this.s3Files.length > 4) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.onDocumentItemSelect
      );
      // alert("You can not select more than 5 files");
      return;
    }
    if (event.target.files.length > 5) {
      // alert("You can not select more than 5 files");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.onDocumentItemSelect
      );
    } else {
      this.isUploadFile = true;
      for (let i = 0; i < event.target.files.length; i++) {
        if (
          this.uploadedDocumentsList.find(
            (x) => x.DocumentName == event.target.files[i].name
          )
        ) {
          // alert(
          //   "A file with the same name (" +
          //     event.target.files[i].name +
          //     ") has been already uploaded. Please select the file from the Select file section"
          // );
          const alertStatus = await this.sweetAlertHelper.alertPopUp(
            this.message.alertMessages.uploadDocComp.onFileSelected2 +
              event.target.files[i].name +
              this.message.alertMessages.uploadDocComp.onFileSelected3
          );
        } else {
          this.uploadFileOnS3(event.target.files[i], this.s3Files);
          this.ppData[i].practices = this.practices;
          this.files.push(event.target.files[i]);
        }
      }
    }
  }

  async uploadFile() {
    let isHippa = 0;

    this.getCheckedBoxValue();
    if (this.selectedDoctorsId.length <= 0) {
      // alert("Please select at least one doctor");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.uploadFile
      );
      return false;
    }
    const fSize = Math.round(this.fileToUpload.size / 1024);
    if (!this.validateFile(this.fileToUpload.name)) {
      // alert("You can only upload .png, .jpg, .jpeg. or .pdf file formats.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.uploadFile1
      );
      return false;
    } else if (fSize > 61440) {
      // alert("File too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.uploadFile2
      );
    } else {
      if (this.isHippByAdmin === true) {
        isHippa = 1;
      } else {
        isHippa = 0;
      }
      // if (
      //   confirm("PLEASE CONFIRM: Are you sure you want to upload the file?")
      // )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.uploadMulitipleDocComp.uploadFile
      );
      if (alertStatus) {
        this.uploadService
          .uploadFileEsign(
            this.fileToUpload,
            this.loggedInUserId,
            this.docType,
            this.selectedDoctorsId,
            this.fileToUpload.name,
            isHippa,
            this.previousDocId
          )
          .subscribe(
            async (data: any) => {
              if (data !== undefined) {
                // console.log(data.message);
                // if (data.message == 100) {
                // if (
                //   confirm(
                //     "PLEASE CONFIRM:   By clicking ‘OK’: Upload an additional file up to a maximum of five (5). To upload additional files, return to the ‘Home Page’ and reselect ‘Upload Files’.  ‘Cancel’ returns to the ‘Home Page’"
                //   )
                // )
                const alertStatus =
                  await this.sweetAlertHelper.confirmPopUpYesOrNo(
                    this.message.confirmTitle,
                    this.message.confirmMessages.uploadDocumentComp.uploadFile1
                  );
                if (alertStatus) {
                  window.location.reload();
                } else {
                  this.router.navigate(["/dashboard/" + this.loggedInUserId]);
                }
                // }
              }
            },
            (error) => {
              // console.log(error);
            }
          );
      } else {
        this.router.navigate(["/dashboard/" + this.loggedInUserId]);
      }
    }
  }

  submitUploadFile() {
    // console.log(this.isUploadFile, this.files);
    const dataArray = [];
    // if(this.isUploadFile){
    this.s3Files.forEach((element, i) => {
      if (i == 0) {
        this.choosedPhysicians = this.finalPhysician01;
        this.choosedPractices = this.tempPractice01;
      }
      if (i == 1) {
        this.choosedPhysicians = this.finalPhysician02;
        this.choosedPractices = this.tempPractice02;
      }
      if (i == 2) {
        this.choosedPhysicians = this.finalPhysician03;
        this.choosedPractices = this.tempPractice03;
      }
      if (i == 3) {
        this.choosedPhysicians = this.finalPhysician04;
        this.choosedPractices = this.tempPractice04;
      }
      if (i == 4) {
        this.choosedPhysicians = this.finalPhysician05;
        this.choosedPractices = this.tempPractice04;
      }

      const obj = {
        RecordId: element.RecordId,
        UploadedPracticesListSelected: this.choosedPractices,
        UploadedPhysicanListSelected: this.choosedPhysicians,
        file: element.fileUrl,
        fileName: element.DocumentName,
      };
      dataArray.push(obj);
    });

    const reqData = {
      isUploadFile: this.isUploadFile,
      userId: this.loggedInUserId,
      data: dataArray,
      docType: this.docType,
    };

    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.uploadEsignMultipleFiles,
        reqData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          // console.log(apiResponsePhysician);
          // if (
          //   confirm(
          //     "PLEASE CONFIRM:   By clicking ‘OK’: Upload an additional file up to a maximum of five (5). To upload additional files, return to the ‘Home Page’ and reselect ‘Upload Files’.  ‘Cancel’ returns to the ‘Home Page’"
          //   )
          // )
          const alertStatus = await this.sweetAlertHelper.confirmPopUp(
            this.message.confirmTitle,
            this.message.confirmMessages.uploadDocumentComp.submitUploadFile
          );
          if (alertStatus) {
            window.location.reload();
          } else {
            this.router.navigate(["/dashboard/" + this.loggedInUserId]);
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async gotToDashboard() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to return to the Home Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      // this.router.navigate(['/dashboard/' + this.loggedInUserId]);
      this.router.navigate(["/dashboard/" + this.loggedInUserId + "/1"]);
    }
  }

  validateFile(name: String) {
    const ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "tif"
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkCheckBoxvalue(event) {
    // console.log(event.checked);
  }

  clearSelectedDoctors() {
    this.selectedDoctorsId = [];
  }

  getCheckedBoxValue() {
    const allCheck: any = document.getElementsByClassName("docChecks");
    for (let index = 0; index < allCheck.length; index++) {
      const element = allCheck[index];
      if (element.checked) {
        this.selectedDoctorsId.push(element.value);
      }
    }
    // console.log(this.selectedDoctorsId);
  }

  fnFindUploadedDocs(profileObj) {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: null,
      docType: ConstantHelper.DOC_TYPE_USER_UPLOADED,
      email: profileObj.Email,
    };

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_getUploadedDocumentsListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            const docList = this.resultJsonObj.commandResult.data.documentList;
            this.uploadedDocumentsList = [];
            docList.forEach((element) => {
              // console.log(element)
              element.uploadedPracticesListSelected = [];
              element.uploadedPhysicanListSelected = [];
              element.uploadedPhysicanListSelected = [];
              this.uploadedDocumentsList.push(element);
            });
            // console.log('uploadedDocumentsList==>', this.uploadedDocumentsList);
            this.uploadedDropdownSettings = {
              singleSelection: false,
              idField: "RecordId",
              textField: "DocumentName",
              selectAllText: "Select All",
              unSelectAllText: "UnSelect All",
              itemsShowLimit: 3,
              allowSearchFilter: true,
            };
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: null,
      id: userId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.profileObj = apiResponse.commandResult.data.userInfo;
            // console.log('this.profileObj', this.profileObj);
            this.fnFindUploadedDocs(this.profileObj);
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  addPreviousDocument(previousDocId) {
    this.previousDocId = previousDocId;
  }

  async goToDashboard() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }

  async onDocumentItemSelect(item: any) {
    // console.log(item);
    // console.log(this.s3Files.length);
    if (this.s3Files.length > 4) {
      // alert("You can not select more than 5 files");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.onDocumentItemSelect
      );
      return;
    }
    this.isUploadFile = false;
    this.updateSelectedDocs();
  }

  async onDocumentSelectAll(items: any) {
    // console.log(items);
    if (items.length > 4) {
      // alert("You can not select more than 5 files");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.onDocumentItemSelect
      );
      return;
    }
    this.updateSelectedDocs();
    this.isUploadFile = false;
  }

  onPracticeItemSelect(item: any, i) {
    const testData = this.s3Files[i].uploadedPracticesListSelected;
    // console.log(testData);
    this.getPhysican(testData[0].company_url, i);
  }

  onPracticeSelectAll(items: any, i) {
    this.isDisabled = true;
    // console.log(items);
  }

  onPracticeItemDeSelect(item: any, i) {}

  onDocumentItemDeSelect(item: any) {
    this.s3Files = [];
    this.updateSelectedDocs();
  }

  updateSelectedDocs() {
    this.uploadedDocumentsListSelected.forEach((element, i) => {
      // console.log('hgghg=>', i);
      this.ppData[i].practices = this.practices;
      this.uploadedDocumentsListSelected[i].uploadedPracticesListSelected = [];
      this.uploadedDocumentsListSelected[i].uploadedPhysicanListSelected = [];
      if (!this.s3Files.find((x) => x.RecordId == element.RecordId)) {
        this.s3Files.push(element);
      }
      // console.log(this.s3Files);
    });
  }

  onPhysicanItemSelect(item: any, i) {
    // console.log('itemp', item);
    if (i == 0) {
      this.tempPhysician01 = this.s3Files[0].uploadedPhysicanListSelected;
    }
    if (i == 1) {
      this.tempPhysician02 = this.s3Files[1].uploadedPhysicanListSelected;
    }
    if (i == 2) {
      this.tempPhysician03 = this.s3Files[2].uploadedPhysicanListSelected;
    }
    if (i == 3) {
      this.tempPhysician04 = this.s3Files[3].uploadedPhysicanListSelected;
    }
    if (i == 4) {
      this.tempPhysician05 = this.s3Files[4].uploadedPhysicanListSelected;
    }
  }

  onPhysicanSelectAll(item: any, i) {
    // console.log('itemp', item);
    if (i == 0) {
      item.forEach((element, i) => {
        this.tempPhysician01.push(element);
      });
    }
    if (i == 1) {
      item.forEach((element, i) => {
        this.tempPhysician02.push(element);
      });
    }
    if (i == 2) {
      item.forEach((element, i) => {
        this.tempPhysician03.push(element);
      });
    }
    if (i == 3) {
      item.forEach((element, i) => {
        this.tempPhysician04.push(element);
      });
    }
    if (i == 4) {
      item.forEach((element, i) => {
        this.tempPhysician05.push(element);
      });
    }
  }

  onPhysicanItemDeSelect(item: any, i) {
    if (item.length <= 0 && i == 0) {
      this.tempPhysician01 = [];
    }
    if (item.length <= 0 && i == 1) {
      this.tempPhysician02 = [];
    }
    if (item.length <= 0 && i == 2) {
      this.tempPhysician03 = [];
    }
    if (item.length <= 0 && i == 3) {
      this.tempPhysician04 = [];
    }
    if (item.length <= 0 && i == 4) {
      this.tempPhysician05 = [];
    }
  }

  async listData(i, file) {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: By clicking ‘OK’: Adds the selected Physician to your list of Approved Registered Providers.  This selection, once made, cannot be deleted, to archive all document sharing in your database."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.uploadDocumentComp.listData
    );
    if (alertStatus) {
      if (i == 0) {
        if (this.physicianInfo1 == "all") {
          this.tempPractice01.push(
            this.practices.find((x) => x.company_url == this.practiceInfo1)
          );
          // this.physicans1.forEach(element => {
          //   this.finalPhysician01.push(element);
          // });

          // console.log(this.tempPractice01);
        } else {
          this.physicianInfo1.forEach((element) => {
            this.finalPhysician01.push(
              this.physicans1.find((x) => x.physician_id == element)
            );
            this.tempPhysician01.push(
              this.physicans1.find((x) => x.physician_id == element)
            );
          });
        }
        this.show01 = true;
        this.practiceInfo1 = "";
        this.physicans1 = [];
        this.physicianLength1 = false;
      }

      if (i == 1) {
        if (this.physicianInfo2 == "all") {
          this.tempPractice02.push(
            this.practices.find((x) => x.company_url == this.practiceInfo2)
          );
          // this.physicans2.forEach(element => {
          //   this.finalPhysician02.push(element);
          // });
          // console.log(this.tempPractice02);
        } else {
          this.physicianInfo2.forEach((element) => {
            this.finalPhysician02.push(
              this.physicans2.find((x) => x.physician_id == element)
            );
            this.tempPhysician02.push(
              this.physicans2.find((x) => x.physician_id == element)
            );
          });
        }
        this.show02 = true;
        this.practiceInfo2 = "";
        this.physicans2 = [];
        this.physicianLength2 = false;
      }

      if (i == 2) {
        if (this.physicianInfo3 == "all") {
          this.tempPractice03.push(
            this.practices.find((x) => x.company_url == this.practiceInfo3)
          );
          // this.physicans3.forEach(element => {
          //   this.finalPhysician03.push(element);
          // });
          // console.log(this.tempPractice03);
        } else {
          this.physicianInfo3.forEach((element) => {
            this.finalPhysician03.push(
              this.physicans3.find((x) => x.physician_id == element)
            );
            this.tempPhysician03.push(
              this.physicans3.find((x) => x.physician_id == element)
            );
          });
        }
        this.show03 = true;
        this.practiceInfo3 = "";
        this.physicans3 = [];
        this.physicianLength3 = false;
      }

      if (i == 3) {
        if (this.physicianInfo4 == "all") {
          this.tempPractice04.push(
            this.practices.find((x) => x.company_url == this.practiceInfo4)
          );
          // this.physicans4.forEach(element => {
          //   this.finalPhysician04.push(element);
          // });
          // console.log(this.tempPractice04);
        } else {
          this.physicianInfo4.forEach((element) => {
            this.finalPhysician04.push(
              this.physicans4.find((x) => x.physician_id == element)
            );
            this.tempPhysician04.push(
              this.physicans4.find((x) => x.physician_id == element)
            );
          });
        }
        this.show04 = true;
        this.practiceInfo4 = "";
        this.physicans4 = [];
        this.physicianLength4 = false;
      }

      if (i == 4) {
        if (this.physicianInfo5 == "all") {
          this.tempPractice05.push(
            this.practices.find((x) => x.company_url == this.practiceInfo5)
          );
          // this.physicans5.forEach(element => {
          //   this.finalPhysician05.push(element);
          // });
          // console.log(this.tempPractice05);
        } else {
          this.physicianInfo5.forEach((element) => {
            this.finalPhysician05.push(
              this.physicans5.find((x) => x.physician_id == element)
            );
            this.tempPhysician05.push(
              this.physicans5.find((x) => x.physician_id == element)
            );
          });
        }
        this.show05 = true;
        this.practiceInfo5 = "";
        this.physicans5 = [];
        this.physicianLength5 = false;
      }
    }
  }

  getFileDetails(file, i) {
    // console.log('file, i', file, i);
  }

  uploadFileOnS3(file, arrayData) {
    // console.log(arrayData);
    const contentType = file.type;
    const bucket = new S3({
      accessKeyId: ConstantHelper.AWS_ACCESS_KEY,
      secretAccessKey: ConstantHelper.AWS_SECRET_KEY,
      region: ConstantHelper.AWS_REGION,
    });
    const params = {
      Bucket: ConstantHelper.AWS_BUCKET,
      Key: file.name,
      Body: file,
      ACL: ConstantHelper.AWS_ACL,
      ContentType: contentType,
    };
    const finalData = [];
    return bucket.upload(params, function (err, data) {
      if (err) {
        // console.log('There was an error uploading your file: ', err);
        return false;
      }
      const obj = {
        uploadedPracticesListSelected: [],
        uploadedPhysicanListSelected: [],
        RecordId: 0,
        fileUrl: data.Location,
        DocumentName: file.name,
      };

      if (!arrayData.find((x) => x.DocumentName == obj.DocumentName)) {
        arrayData.push(obj);
      }
      // console.log('Successfully uploaded file.', obj, arrayData);
      return obj;
    });

    // this.s3Files = finalData;

    // console.log("=====================" ,this.s3Files);
  }

  setClass() {
    this.setStatus();
    if (this.files.length > 0) {
      return "validField";
    } else {
      return "invalidField";
    }
  }

  setStatus() {
    // (this.selectedItems.length > 0) ? this.requiredField = true : this.requiredField = false;
  }

  onChangePractice(companyUrl, i) {
    const reqData = {
      company_slug: companyUrl,
      i,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findPhysiciansByCompanySlugApi,
        reqData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (i == 0) {
            this.physicans1 = this.fnChangeDocumentPhysicianNameFormat(
              apiResponse.commandResult.data.companiesList
            );

            this.disabled1 = false;
            if (this.physicans1.length > 0) {
              this.physicianLength1 = true;
            }
          }
          if (i == 1) {
            this.physicans2 = this.fnChangeDocumentPhysicianNameFormat(
              apiResponse.commandResult.data.companiesList
            );
            this.disabled2 = false;

            if (this.physicans2.length > 0) {
              this.physicianLength2 = true;
            }
            // console.log(this.physicans2);
          }
          if (i == 2) {
            this.physicans3 = this.fnChangeDocumentPhysicianNameFormat(
              apiResponse.commandResult.data.companiesList
            );
            this.disabled3 = false;

            if (this.physicans3.length > 0) {
              this.physicianLength3 = true;
            }
            // console.log(this.physicans3);
          }
          if (i == 3) {
            this.physicans4 = this.fnChangeDocumentPhysicianNameFormat(
              apiResponse.commandResult.data.companiesList
            );
            this.disabled4 = false;

            if (this.physicans4.length > 0) {
              this.physicianLength4 = true;
            }
            // console.log(this.physicans4);
          }
          if (i == 4) {
            this.physicans5 = this.fnChangeDocumentPhysicianNameFormat(
              apiResponse.commandResult.data.companiesList
            );
            this.disabled5 = false;

            if (this.physicans5.length > 0) {
              this.physicianLength5 = true;
            }
            // console.log(this.physicans5);
          }

          // this.companiesListLength = true;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  onChangePhysician(value, j) {
    // console.log(value);
    // console.log(j);
    if (value == "all" && j == 0) {
      this.disabled1 = true;
    }
    if (value == "all" && j == 1) {
      this.disabled2 = true;
    }
    if (value == "all" && j == 2) {
      this.disabled3 = true;
    }
    if (value == "all" && j == 3) {
      this.disabled4 = true;
    }
    if (value == "all" && j == 4) {
      this.disabled5 = true;
    }

    //disable1
  }

  fndPremission(RecordId, i) {
    if (
      i == 0 &&
      this.uploadedDocumentsList.find((x) => x.RecordId == RecordId)
    ) {
      this.previou_permission1 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previou_permissions;
      this.previou_practice_permission1 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previouse_practice_permissions;
      // console.log(this.previou_practice_permission1);
    }
    if (
      i == 1 &&
      this.uploadedDocumentsList.find((x) => x.RecordId == RecordId)
    ) {
      this.previou_permission2 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previou_permissions;
      this.previou_practice_permission2 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previouse_practice_permissions;
      // console.log(this.previou_practice_permission2);
    }
    if (
      i == 2 &&
      this.uploadedDocumentsList.find((x) => x.RecordId == RecordId)
    ) {
      this.previou_permission3 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previou_permissions;
      this.previou_practice_permission3 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previouse_practice_permissions;
      // console.log(this.previou_practice_permission3);
    }
    if (
      i == 3 &&
      this.uploadedDocumentsList.find((x) => x.RecordId == RecordId)
    ) {
      this.previou_permission4 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previou_permissions;
      this.previou_practice_permission4 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previouse_practice_permissions;
      // console.log(this.previou_permission4);
    }
    if (
      i == 4 &&
      this.uploadedDocumentsList.find((x) => x.RecordId == RecordId)
    ) {
      this.previou_permission5 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previou_permissions;
      this.previou_practice_permission5 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previouse_practice_permissions;
      // console.log(this.previou_permission5);
    }
  }

  fnChangeDocumentPhysicianNameFormat(physicians) {
    let formattedNameArray: any = [];
    physicians.forEach((e) => {
      var nameArr = e.physician.split("(");
      e.physician =
        this.userService.getUS_FromatName(
          nameArr[0].replace(/^\s+|\s+$/g, "")
        ) +
        " (" +
        nameArr[1];
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(this.userService.dynamicSort("physician"));
  }
}
