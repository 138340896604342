import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { DoctorMailService } from "../../_services/doctor-mail.service";
import { UserService } from "../../_services/user.service";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";
@Component({
  selector: "app-user-reply-d2d",
  templateUrl: "./user-reply-d2d.component.html",
  styleUrls: ["./user-reply-d2d.component.scss"],
})
export class UserReplyD2dComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  pId: string;
  logId: string;
  loggedInUserId: string;
  userOptionId: string;
  logDetails: any = {};
  showLoader = false;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    private uploadService: UploadService,
    private doctorMailService: DoctorMailService,
    private userService: UserService
  ) {
    this.pId = this.activatedRoute.snapshot.paramMap.get("pId");
    this.logId = this.activatedRoute.snapshot.paramMap.get("logId");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.userOptionId =
      this.activatedRoute.snapshot.paramMap.get("userOptionId");
  }
  ngOnInit() {
    this.findD2DLogDetail(this.logId);
  }
  onSubmit() {}

  findD2DLogDetail(logId) {
    const finalData = {
      logId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findD2DLogDetail,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.logDetails = res.commandResult.data.log;

          this.logDetails.ReuestedDoctorEmail = this.commonHelper.maskEmail(
            this.commonHelper.cleanEmail(
              res.commandResult.data.log.ReuestedDoctorEmail
            )
          );

          this.showLoader = false;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  async goToDashboard() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘OK’: You will be returned to the ‘Home Page’."
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.ushfComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    // }
  }

  async back() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘OK’: You will be returned back to exper opinion log."
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.userReplyComp.back
    // );
    // if (alertStatus) {
      this.router.navigate([
        "/expert-physician-opinion-log/" + this.loggedInUserId,
      ]);
    // }
  }
}
