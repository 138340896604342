import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "./../../_services/user.service";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";
import { async } from "@angular/core/testing";
@Component({
  selector: "app-expert-physician-opinion-log",
  templateUrl: "./expert-physician-opinion-log.component.html",
  styleUrls: ["./expert-physician-opinion-log.component.scss"],
})
export class ExpertPhysicianOpinionLogComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  loggedInUserId: string;

  logThree: any = [];
  selectedPatient: any = {};
  selectedRequesting: any = {};
  showPatientModal = false;
  showRequestingModal = false;
  loggedInUserRoleId = localStorage.getItem("loggedInUserRoleId");
  onBehalfOfAlertExpertOpinionLog = "Y";
  message = Message;
  showDataVal = 0;
  showLoader = false;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService
  ) {}

  async ngAfterViewInit() {
    var item = localStorage.getItem("FullName");
    if (
      this.onBehalfOfAlertExpertOpinionLog === "Y" &&
      localStorage.getItem("isDelegating") === "1"
    ) {
      // alert(
      //   "Dear User, you are doing this activity on behalf of " +
      //     localStorage.getItem("FullName")
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.ngAfterViewInit + " " + item
      );

      localStorage.setItem("onBehalfOfAlertExpertOpinionLog", "N");
    }
  }
  ngOnInit() {
    this.dtOptions = {
      stateSave: true,
      order: []
    };
    // alert(this.loggedInUserRoleId);
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.findTransferLog(this.loggedInUserId, 3);
    this.onBehalfOfAlertExpertOpinionLog = localStorage.getItem(
      "onBehalfOfAlertExpertOpinionLog"
    )
      ? localStorage.getItem("onBehalfOfAlertExpertOpinionLog")
      : "Y";
    window.scroll(0,0)
    this.showDataVal = 3
  }

  async goToDemographics() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to physician Home Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/dashboard/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    }
  }

  findTransferLog(userId, logType) {
    this.logThree = [];
    const finalData = {
      userId,
      logType,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findPatientOpinionLog,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.showLoader = false;
          if (res.commandResult.data.logs.length > 0) {
            this.logThree = res.commandResult.data.logs;
          }

          // console.log(this.logThree);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  viewDouments(logDetails) {
    // console.log(logDetails);
    // tslint:disable-next-line: max-line-length
    this.router.navigate([
      "/user-docs/" +
        logDetails.UniqueKey +
        "/" +
        logDetails.RecordId +
        "/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/" +
        logDetails.UserOptionId,
    ]);
  }

  reviewLog(logDetails) {
    // console.log(logDetails);
    // tslint:disable-next-line: max-line-length
    this.router.navigate([
      "/reply-d2d/" +
        logDetails.UniqueKey +
        "/" +
        logDetails.RecordId +
        "/" +
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
        "/" +
        logDetails.UserOptionId,
    ]);
  }

  async goToDashboard() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘OK’: You will be returned to the ‘Home Page’."
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.ushfComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate([
        "/dashboard/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    // }
  }

  showData(selectedVal) {
    this.showDataVal = selectedVal
    this.findTransferLog(this.loggedInUserId, selectedVal);
  }
}
