import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';

import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {AppConfig} from './../../../app/app.config';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {DoctorMailService} from '../../_services/doctor-mail.service';
import {UserService} from '../../_services/user.service';
import {LocalStorageService} from '../../_services/local-storage.service';

import {SweetAlertHelper} from '../../_helpers/sweet.alert.helper';
import {Message} from '../../_locale/messages';
import {async} from '@angular/core/testing';

@Component({
  selector: 'app-expert-physician-opinion2',
  templateUrl: './expert-physician-opinion2.component.html',
  styleUrls: ['./expert-physician-opinion2.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class ExpertPhysicianOpinion2Component implements OnInit {
  @ViewChild('fileUploadedHippa', {static: false})
  fileUploadedHippa: ElementRef;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  companySlug: string;
  loggedInUserId: string;
  userOptionId: string;
  isHippa: any;
  tempId: any;
  documentId: any;
  showLoader = false;
  maxChars = 300;
  documentFolderType = 'A';
  showNewFile = 0;
  isProgrammed = false;
  hippaAvailable = false;
  hasHippaFile = false;
  isPracticePhy = false;
  fileDesTxt: any = '';
  practicingCenterId: any = '';
  requestingPhyMobile: any = '';
  requestingPhyEmail: any = '';
  hippaFileDesTxt: any = '';
  selectedEmail: any;
  practiceSlug: any = '';
  physicianType = '';
  physicianId: any = '';
  specialtyId: any = '';
  subSpecialtyId: any = '';
  consultantId: any = '';
  company_slug = '';
  stateName = '';
  cityName = '';
  stateCode = '';
  attachmentDocment: any = '';
  selectedNPI: any;
  selectedPhyName: any;
  requestingPhyEmailVal: any;
  requestingPhyMobileVal: any;
  reuestTimeVal: any = '1';
  selectedDocumentId: any;
  showComleteHippa = false;
  selectedPhysician: any = {};
  attachmentDoc: any = {};
  message = Message;

  docSelected: any = {};
  // tslint:disable-next-line: variable-name
  selected_spacialty_id: any = {};
  // tslint:disable-next-line: variable-name

  // tslint:disable-next-line: variable-name
  selected_sub_specialty_id: any = {};
  // tslint:disable-next-line: variable-name
  selected_spacialty: any = {};

  selected_sub_specialty: any = {};

  statesArre: any = [];
  citiesList: any = [];
  physiciansList: any = [];
  practices: any = [];
  specialityArr: any = [];
  practicingCenterArr: any = [];
  documentsList: any = [];
  hippaDocumentsList: any = [];
  subSpecialtyArr: any = [];
  consultantArr: any = [];
  selectedDocsId: any = [];
  physiciansArr: any = [];
  patientName: any = '';
  fileToUploadHippa: File = null;
  fSizeHippa: any;
  showComfirmationModal = false;
  selectedStateCode: any;
  selectedDocUrl: any;
  tempObj: any = {};
  physicianName: any;
  isOnCall = false;
  selectedFiles: any = [];
  specialtyName: any;
  subSpecialtyName: any;
  documentSetType: any = 'E';

  selectedSpecialityObj: any = {};
  selectedSubSpecialityObj: any = {};
  onBehalfOfAlertExpertOpinion = 'Y';
  isFileSelected = false;
  selectedIndexes: boolean[] = [];
  selectedStateByRecordId: { [key: string]: boolean } = {};

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private doctorMailService: DoctorMailService,
    private userService: UserService,
    public localStorageService: LocalStorageService,
  ) {
    this.loggedInUserId = localStorage.getItem('delegateId')
      ? localStorage.getItem('delegateId')
      : this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.userOptionId =
      this.activatedRoute.snapshot.paramMap.get('userOptionId');
    this.isHippa = this.activatedRoute.snapshot.paramMap.get('isHippa') ? this.activatedRoute.snapshot.paramMap.get('isHippa') : '0';
    this.attachmentDocment = this.activatedRoute.snapshot.paramMap.get(
      'documentId'
    )
      ? this.activatedRoute.snapshot.paramMap.get('documentId')
      : '';
    if (this.attachmentDocment) {
      this.isFileSelected = true;
    }
    this.tempId = this.activatedRoute.snapshot.paramMap.get('tempId')
      ? this.activatedRoute.snapshot.paramMap.get('tempId')
      : '';
  }

  async ngAfterViewInit() {
    let item = localStorage.getItem('FullName');
    if (
      this.onBehalfOfAlertExpertOpinion === 'Y' &&
      localStorage.getItem('isDelegating') === '1'
    ) {
      // alert(
      //   "Dear User, you are doing this activity on behalf of " +
      //     localStorage.getItem("FullName")
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.ngAfterViewInit + ' ' + item
      );
      localStorage.setItem('onBehalfOfAlertExpertOpinion', 'N');
    }
  }

  ngOnInit() {
    this.findSpecialty(this.loggedInUserId);
    this.fnFindHippaDocs(this.loggedInUserId, 0);
    this.findUserDetails(this.loggedInUserId);
    this.fnFindAllDocuments(this.loggedInUserId);
    if (this.isHippa == 1) {
      this.findTempDetails(this.tempId);
    }
    this.onBehalfOfAlertExpertOpinion = localStorage.getItem('onBehalfOfAlertExpertOpinion') ? localStorage.getItem('onBehalfOfAlertExpertOpinion') : 'Y';
    if (this.isHippa == 0) {
      this.localStorageService.removeItem('selectedFiles');
      this.localStorageService.removeItem('selectedDocsId');
      localStorage.removeItem('fileDesTxt');
    } else {
      this.selectedFiles = this.localStorageService.getArray('selectedFiles');
      this.selectedDocsId = this.localStorageService.getArray('selectedDocsId');
      this.fileDesTxt = localStorage.getItem('fileDesTxt');
    }
    window.scroll(0,0)
  }

  findTempDetails(tempId) {
    const finalData = {
      tempId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() +
        ApiHelper.php_getTempPatientExpertOpinionApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.tempObj = res.commandResult.data.tempData;
          this.practicingCenterId = this.tempObj.practicingCenterId;
          this.specialtyId = this.tempObj.specialtyId;
          this.subSpecialtyId = this.tempObj.subSpecialtyId;
          this.specialtyName = this.tempObj.speciality;
          this.subSpecialtyName = this.tempObj.sub_speciality;
          this.physicianId = this.tempObj.physician_id;
          this.physicianName = this.tempObj.full_name;
          this.docSelected = this.tempObj;
          this.docSelected.maskedEmail =
            this.docSelected.email.substring(0, 4) + '****@***.***';
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindAllDocuments(userId) {
    const finalData = {
      userId,
      company_slug: null,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getPatientAllDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            this.documentsList = res.commandResult.data.documentList;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindHippaDocs(userId, addToDocList) {
    const finalData = {
      loggedInUserId: userId,
      company_slug: null,
      doc_group_id: 'HD',
      default_group_id: 17,
    };

    // console.log(finalData);
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findDocsBySubGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            if (addToDocList == 1) {
              this.documentsList = res.commandResult.data.documentList;
            }
            this.hippaDocumentsList = res.commandResult.data.documentList;
            // alert(this.isHippa);
            if (this.isHippa == 1) {
              this.setAttachment(this.attachmentDocment);
            }
          } else {
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDetails(loggedInUserId) {
    const finalData = {
      id: loggedInUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.patientName = apiResponse.commandResult.data.userInfo.FullName;
          this.requestingPhyEmailVal =
            apiResponse.commandResult.data.userInfo.Email;
          this.requestingPhyMobileVal =
            apiResponse.commandResult.data.userInfo.Mobile;

          // console.log(apiResponse.commandResult.data.userInfo);
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findAllParticipatingCenters() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getPractices,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.practices.length > 0) {
            this.practices = apiResponse.commandResult.data.practices;
          } else {
            // alert("No Center of Excellence found!");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.sendDocComp.findAllParticipatingCenters
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findSpecialtyByCompanySlug(specialitSlug) {
    // console.log(specialitSlug);
    // get_SpecialtyByPractices
    this.company_slug = specialitSlug;
    this.docSelected = {};
    this.physicianId = '';
    this.subSpecialtyId = '';
    this.specialtyId = '';
    const finalData = {
      companySlug: specialitSlug,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.get_SpecialtyByPractices,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.data.specialities.length > 0) {
            this.specialityArr = apiResponse.commandResult.data.specialities;
            // console.log('Specialties', this.specialityArr);
            this.docSelected = {};
          } else {
            // alert("No Specialties found!");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.expertPhysicianComp.findSpecialty
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnChangePraticipatingPhysicianNameFormat(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.full_name = this.userService.getUS_FromatName(e.full_name);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(this.userService.dynamicSort('full_name'));
  }

  updatePracticingPhysicianInfo(physicianId) {
    this.docSelected = this.physiciansArr.find(
      (e) => e.physician_id == physicianId
    );

    console.log(this.docSelected.physician_id);
    this.selectedNPI = this.docSelected.nip_no;
    this.selectedPhyName = this.docSelected.full_name;
    this.selectedEmail = this.docSelected.email;
    this.selected_spacialty_id = this.docSelected.speciality_id;
    this.selected_sub_specialty_id = this.docSelected.sub_speciality_id;
    this.docSelected.maskedEmail =
      this.docSelected.email.substring(0, 4) + '****@***.***';
    localStorage.setItem('selectedHippaState',this.docSelected.physician_state);
    localStorage.setItem('providerName', this.docSelected.full_name);
    localStorage.setItem('providerOfficeAddress',this.docSelected.physician_address);
    localStorage.setItem('providerZipCode', '');
    localStorage.setItem('providerEmail', this.selectedEmail);
    localStorage.setItem('providerPhysicianName', this.selectedEmail);
    localStorage.setItem('providerPhysicianNipNo', this.docSelected.nip_no);
    if (
      this.docSelected.physician_state == 'NJ' ||
      this.docSelected.physician_state == 'NY' ||
      this.docSelected.physician_state == 'FL' ||
      this.docSelected.physician_state == 'PA' ||
      this.docSelected.physician_state == 'CT' ||
      this.docSelected.physician_state == 'OH' ||
      this.docSelected.physician_state == 'CO' ||
      this.docSelected.physician_state == 'CA' ||
      this.docSelected.physician_state == 'AL' ||
      this.docSelected.physician_state == 'AZ' ||
      this.docSelected.physician_state == 'AR' ||
      this.docSelected.physician_state == 'DE' ||
      this.docSelected.physician_state == 'GA' ||
      this.docSelected.physician_state == 'HI' ||
      this.docSelected.physician_state == 'ID' ||
      this.docSelected.physician_state == 'IL' ||
      this.docSelected.physician_state == 'AK' ||
      this.docSelected.physician_state == 'TX' ||
      this.docSelected.physician_state == 'SD' ||
      this.docSelected.physician_state == 'ND' ||
      this.docSelected.physician_state == 'VA' ||
      this.docSelected.physician_state == 'VT' ||
      this.docSelected.physician_state == 'KY' ||
      this.docSelected.physician_state == 'WI' ||
      this.docSelected.physician_state == 'WV' ||
      this.docSelected.physician_state == 'TN'
    ) {
      this.isProgrammed = true;
    } else {
      this.isProgrammed = false;
    }
    this.hippaAvailable = true;
    this.selectedStateCode = this.docSelected.physician_state;
  }

  async downloadHipp() {
    sessionStorage.setItem('sendDocRoute', 'expert-physician-opinion/' + this.loggedInUserId + "/" + this.userOptionId);
    this.selectedStateCode = localStorage.getItem('selectedHippaState')
    if (this.selectedStateCode == 'NJ') {
      this.router.navigate([
        '/hippanj/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'NY') {
      this.router.navigate([
        '/hippany/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'FL') {
      this.router.navigate([
        '/hippafl/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'PA') {
      this.router.navigate([
        '/hippapa/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'CT') {
      this.router.navigate([
        '/hippact/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'OH') {
      this.router.navigate([
        '/hippaoh/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'CO') {
      this.router.navigate([
        '/hippaco/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'CA') {
      this.router.navigate([
        '/hippaca/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'AL') {
      this.router.navigate([
        '/hippaal/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'AZ') {
      this.router.navigate([
        '/hippaaz/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'AR') {
      this.router.navigate([
        '/hippaar/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'DE') {
      this.router.navigate([
        '/hippade/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'GA') {
      this.router.navigate([
        '/hippaga/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'HI') {
      this.router.navigate([
        '/hippahi/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'ID') {
      this.router.navigate([
        '/hippaid/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'IL') {
      this.router.navigate([
        '/hippail/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'AK') {
      this.router.navigate([
        '/hippaak/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'TX') {
      this.router.navigate([
        '/hippatx/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'SD') {
      this.router.navigate([
        '/hippasd/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'ND') {
      this.router.navigate([
        '/hippand/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'VA') {
      this.router.navigate([
        '/hippava/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'VT') {
      this.router.navigate([
        '/hippavt/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'KY') {
      this.router.navigate([
        '/hippaky/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'WI') {
      this.router.navigate([
        '/hippawi/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'WV') {
      this.router.navigate([
        '/hippawv/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else if (this.selectedStateCode == 'TN') {
      this.router.navigate([
        '/hippatn/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/3' +
        '/' +
        this.tempId +
        '/' +
        this.userOptionId,
      ]);
    } else {
      if (this.selectedDocUrl) {
        // alert
        //   "Please download HIPAA form manually and upload, once you filled it."
        // );
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.sendDocComp.downloadHipp
        );

        if (alertStatus) {
          window.open(this.selectedDocUrl);
        }
      } else {
        // alert("No HIPAA form found for the state.");
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.ushfComp.onSubmit5
        );
      }
    }
  }

  saveTempData() {
    const finalData = {
      practicingCenterId: this.practicingCenterId,
      specialtyId: this.specialtyId,
      subSpecialtyId: this.subSpecialtyId,
      selectedNPI: this.selectedNPI,
      requestingPhyEmailVal: this.requestingPhyEmailVal,
      requestingPhyMobileVal: this.requestingPhyMobileVal,
      state: this.docSelected.physician_state,
    };

    this.http
      .post(
        this.appConfig.getLambdaUrl06() +
        // ApiHelper.php_saveTempPatientExpertOpinionApi,
        ApiHelper.saveTempPatientExpertOpinion,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.tempId = res.commandResult.data.tempData;
          // console.log(this.tempId)
          this.selectedDocUrl = res.commandResult.data.selectedDocUrl;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  selectDocGroup(docSet) {
    this.documentsList = [];
    if (docSet == 'A') {
      this.fnFindAllDocuments(this.loggedInUserId);
    } else if (docSet == 'U') {
      this.fnFindUploadedDocs(this.loggedInUserId);
    } else if (docSet == 'DS') {
      this.fnFindDoctorAcknowledgments(this.loggedInUserId);
    } else if (docSet == 'HD') {
      this.fnFindHippaDocuments(this.loggedInUserId);
    } else if (docSet == 'ID') {
      this.fnFindImageDocuments(this.loggedInUserId);
    } else if (docSet == 'VD') {
      this.fnFindVideoDocuments(this.loggedInUserId);
    } else {
      // this.activeGroupId = this.docSet;
      // this.fnFindGoupDocs();
    }
  }

  fnFindPhysicainHippa(userId, docType) {
    const finalData = {
      userId,
      docType,
    };

    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findPhysicainHippaApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.documentsList = apiResponse.commandResult.data.documentList;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindHippaDocuments(userId) {
    const finalData = {
      userId,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
        ApiHelper.getPatientHippaDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            this.documentsList = res.commandResult.data.documentList;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindImageDocuments(userId) {
    const finalData = {
      userId,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
        ApiHelper.getPatientImageDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            this.documentsList = res.commandResult.data.documentList;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindVideoDocuments(userId) {
    const finalData = {
      userId,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
        ApiHelper.getPatientVideoDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            this.documentsList = res.commandResult.data.documentList;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindUploadedDocs(userId) {
    const finalData = {
      userId,
      company_slug: null,
      docType: ConstantHelper.DOC_TYPE_USER_UPLOADED,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    // console.log(finalData);
    // return;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
        ApiHelper.getPatientUploadedDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            this.documentsList = res.commandResult.data.documentList;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindDoctorAcknowledgments(userId) {
    const finalData = {
      userId,
      company_slug: null,
      docType: 7,
      physicianId: this.documentSetType,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
        ApiHelper.getPatientAcknowledgmentDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            this.documentsList = res.commandResult.data.documentList;
          }
          this.showLoader = false;
        },
        (err) => {
          this.showLoader = false;
          // console.log(err);
        }
      );
  }

  async clearSelectedDocs(event, documentInfo) {
    const documentId = documentInfo.RecordId;
  
    if (event.target.checked) {
      // Check if the document is already in the selectedFiles array
      const existsInSelectedFiles = this.selectedFiles.some(doc => doc.RecordId === documentId);
  
      if (!existsInSelectedFiles) {
        // Add the document to the selectedFiles array
        this.selectedFiles.push(documentInfo);
      }
  
      // Update the selected state
      this.selectedStateByRecordId[documentId] = true;
  
      // Add the documentId to the selectedDocsId array
      this.selectedDocsId.push(documentId);
  
      // Update local storage
      this.localStorageService.setArray('selectedFiles', this.selectedFiles);
      this.localStorageService.setArray('selectedDocsId', this.selectedDocsId);
      localStorage.setItem('fileDesTxt', this.fileDesTxt);
    } else {
      // If unchecked, remove the document without showing a confirmation dialog
      this.removeSelectedFile(documentId, false);
  
      // Update the selected state
      this.selectedStateByRecordId[documentId] = false;
    }
  }

  onFileSelectedHippa(event) {
    this.showNewFile = 1;
    this.attachmentDocment = '';
    this.attachmentDoc = {};

    this.hippaAvailable = false;

    if (this.fileUploadedHippa.nativeElement.value != '') {
      this.fileToUploadHippa = event.target.files.item(0) as File;
      this.fSizeHippa = Math.round(this.fileToUploadHippa.size / 1024);
    } else {
    }

    if (
      this.hasHippaFile &&
      this.fileUploadedHippa.nativeElement.value == '' &&
      this.selectedDocumentId == ''
    ) {
      this.hippaAvailable = true;
    }
    this.isFileSelected = true;
  }

  updateFileStatus() {
    this.isFileSelected = true;
  }

  setAttachment(DocumentId) {
    // alert(DocumentId)
    this.hippaAvailable = false;
    this.fileUploadedHippa.nativeElement.value = '';
    this.selectedDocumentId = DocumentId;
    this.showNewFile = 2;
    const docObj = this.hippaDocumentsList.find(
      (e) => e.RecordId == this.selectedDocumentId
    );
    this.attachmentDoc = JSON.stringify(docObj);
    // console.log(this.attachmentDoc);

    if (
      this.hasHippaFile &&
      this.fileUploadedHippa.nativeElement.value == '' &&
      this.selectedDocumentId == ''
    ) {
      this.hippaAvailable = true;
    }
  }

  async goToDashboard() {
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.ushfComp.onReset);
    // if (alertStatus) {
      this.localStorageService.removeItem('selectedFiles');
      this.localStorageService.removeItem('selectedDocsId');
      localStorage.removeItem('fileDesTxt');
      this.router.navigate(['/dashboard/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),]);
    // }
  }

  async onReset() {
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.sendDocComp.onReset
    // );
    // if (alertStatus) {
      localStorage.removeItem('selectedHippaState');
      localStorage.removeItem('providerName');
      localStorage.removeItem('providerOfficeAddress');
      localStorage.removeItem('providerZipCode');
      localStorage.removeItem('providerEmail');
      localStorage.removeItem('providerPhysicianName');
      localStorage.removeItem('providerPhysicianNipNo');
      this.localStorageService.removeItem('selectedFiles');
      this.localStorageService.removeItem('selectedDocsId');
      localStorage.removeItem('fileDesTxt');
      if (this.isHippa == 0) {
        window.location.reload();
      } else {
        this.router.navigate(['/expert-physician-opinion/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.userOptionId]);
      }
    // }
  }

  async completeHippa() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘OK’: You will be redirected to upload hipaa form."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.expertPhysicianComp.completeHippa
    );
    if (alertStatus) {
      this.router.navigate([
        '/ushf/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
      ]);
    }
  }

  validateFile(name: String) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (
      ext.toLowerCase() == 'png' ||
      ext.toLowerCase() == 'jpg' ||
      ext.toLowerCase() == 'jpeg' ||
      ext.toLowerCase() == 'pdf'
    ) {
      return true;
    } else {
      return false;
    }
  }


    async removeSelectedFile(id, confirmFlag) {
      if (confirmFlag) {
        // Only show the confirmation dialog if confirmFlag is true
        const alertStatus = await this.sweetAlertHelper.confirmPopUp(
          this.message.confirmTitle,
          this.message.confirmMessages.sendDocComp.removeSelectedFile
        );
    
        if (alertStatus) {
          this.removeItems(id);
        }
      } else {
        this.removeItems(id);
      }
    }
    
removeItems(id) {
  this.selectedDocsId = this.selectedDocsId.filter((obj) => obj !== id);
  const removeIndex = this.selectedFiles
    // tslint:disable-next-line:only-arrow-functions
    .map(function(item) {
      return item.RecordId;
    })
    .indexOf(id);
  this.selectedFiles.splice(removeIndex, 1);
  const documentIndex = this.documentsList.findIndex(doc => doc.RecordId === id);
  if (documentIndex > -1) {
    this.selectedIndexes[documentIndex] = false;
  }
}

  async onSubmit() {
    if (!this.physicianId) {
      await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.sendDocComp.onsubmit
      );
      return false;
    } else if (this.selectedDocsId.length <= 0) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.sendDocComp.onSubmit1
      );
      return false;
    } else if (this.selectedDocsId.length > 5) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.sendDocComp.onSubmit2
      );
      return false;
    } else if (!this.isFileSelected) {
      await this.sweetAlertHelper.alertPopUp('ALERT: You must please select a HIPAA form for this doctor or select generate a HIPAA form to attach to the email before the email can be sent.');
      return false;
    } else if (
      this.showNewFile == 1 &&
      this.fileUploadedHippa.nativeElement.value == ''
    ) {
      await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.expertPhysicianComp.onSubmit);
      return false;
    } else if (
      this.fileUploadedHippa.nativeElement.value != '' &&
      !this.validateFile(this.fileToUploadHippa.name)
    ) {
      await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.sendDocComp.onSubmit3);
      return false;
    } else if (
      this.fileUploadedHippa.nativeElement.value != '' &&
      this.fSizeHippa > 61440
    ) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.expertPhysicianComp.onsubmit2
      );
      return false;
    } else {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.expertPhysicianComp.onsubmit
      );
      if (alertStatus) {
        if (this.fileDesTxt.length > 0) {
          localStorage.setItem('fileDesTxt', this.fileDesTxt);
        }
        this.doctorMailService
          .docotr2doctorEmail_Patient(
            this.loggedInUserId,
            this.practicingCenterId,
            1,
            this.physicianId,
            this.specialtyId,
            this.subSpecialtyId,
            this.fileDesTxt,
            11,
            this.userOptionId,
            this.fileToUploadHippa,
            this.selectedDocsId,
            this.hippaFileDesTxt,
            this.showNewFile,
            this.attachmentDoc,
            this.requestingPhyMobileVal,
            this.requestingPhyEmailVal,
            this.reuestTimeVal
          )
          .subscribe(
            async (data: any) => {
              if (data !== undefined) {
                // alert("Email successfully sent. ");
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.sendDocComp.onSubmit5
                );
                if (alertStatus) {
                  this.router.navigate([
                    '/dashboard/' +
                    this.activatedRoute.snapshot.paramMap.get(
                      'loggedInUserId'
                    ),
                  ]);
                }
                if (data.commandResult.success == 1) {
                }
              }
            },
            (error) => {
              // console.log(error);
            }
          );
      }
    }
  }

  findSpecialty(loggedInUserId) {
    const finalData = {
      loggedInUserId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getSpecialties,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.data.specialities.length > 0) {
            this.specialityArr = apiResponse.commandResult.data.specialities;
            // console.log('Specialties', this.specialityArr);
            if (this.isHippa != 1) {
              this.docSelected = {};
            }
          } else {
            // alert("No Specialties found!");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.expertPhysicianComp.findSpecialty
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findSubSpecialtyBySpeciality(specialityId) {
    this.specialtyId = specialityId;
    this.selectedSpecialityObj = this.specialityArr.find(
      (e) => e.id == specialityId
    );
    this.selected_spacialty = this.selectedSpecialityObj.speciality;
    this.docSelected = {};
    this.physicianId = '';
    this.subSpecialtyId = '';
    this.selectedSubSpecialityObj = '';
    const finalData = {
      speciality: specialityId,
      companySlug: this.companySlug
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getSubSpecialties,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.data.sub_specialities.length > 0) {
            this.subSpecialtyArr =
              apiResponse.commandResult.data.sub_specialities;
            // console.log('SubSpecialties', this.subSpecialtyArr);
            this.docSelected = {};
          } else {
            this.showLoader = false;
            // alert("No Sub Specialties found!");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.expertPhysicianComp
                .findSubSpecialtyBySpeciality
            );
            this.resetCoeSelection();
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findCoeBySubspecialty(subSpecialtyId) {
    this.selectedSpecialityObj = this.subSpecialtyArr.find(
      (e) => e.id == subSpecialtyId
    );
    this.selected_sub_specialty = this.selectedSpecialityObj.sub_speciality;
    const finalData = {
      speciality: this.selected_spacialty,
      subSpecialty: this.selectedSpecialityObj.sub_speciality,
    };

    // console.log(finalData);
    // return;
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getCoeBySubSpeciality,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.practices.length > 0) {
            this.practices = apiResponse.commandResult.data.practices;
          } else {
            // alert("No Center of Excellence found!");
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.sendDocComp.findAllParticipatingCenters
            );
            this.resetCoeSelection();
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findCoePhysicians(companySlug) {
    this.company_slug = companySlug;
    this.docSelected = {};
    this.physicianId = '';
    const finalData = {
      companySlug: this.company_slug,
      speciality: this.selected_spacialty,
      subSpeciality: this.selected_sub_specialty,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getCoePhysicians,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.data.physicians.length > 0) {
            this.physiciansArr = this.fnChangePraticipatingPhysicianNameFormat(
              apiResponse.commandResult.data.physicians
            );
            // console.log('Physicians', this.physiciansArr);
          } else {
            this.physiciansArr = {};
            // alert("No physicians found!");
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.findPryPhysiciansByCity
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  resetCoeSelection() {
    this.practicingCenterId = '';
  }

  resetFormControl() {
    this.hippaAvailable = true;
    this.attachmentDocment = '';
    this.fileUploadedHippa.nativeElement.value = '';
    this.isFileSelected = !this.isFileSelected
  }
  toggleSelectedDoc(index: number, documentInfo: any) {
    this.selectedIndexes[index] = !this.selectedIndexes[index];
    if (this.selectedIndexes[index]) {
      const existsInSelectedFiles = this.selectedFiles.some(doc => doc.RecordId === documentInfo.RecordId);
      if (!existsInSelectedFiles) {
        this.selectedFiles.push(documentInfo);
      }
    } else {
      this.removeSelectedFile(documentInfo.RecordId, false);
    }
  }
}
