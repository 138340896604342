import {from} from 'rxjs';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';

import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {IDropdownSettings} from 'ng-multiselect-dropdown';

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';

import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {UploadService} from '../../_services/upload.service';
import {UserService} from './../../_services/user.service';
import {AzureBlobStorageService} from './../../_services/azure-blob-storage.service';
import {AppConfig} from './../../../app/app.config';
import {SweetAlertHelper} from '../../_helpers/sweet.alert.helper';
import {Message} from '../../_locale/messages';

import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

import {versions} from 'process';
import {compilePipeFromMetadata} from '@angular/compiler';
import {async} from '@angular/core/testing';

declare let $: any;

@Component({
  selector: 'app-upload-multiple-documents2',
  templateUrl: './upload-multiple-documents2.component.html',
  styleUrls: ['./upload-multiple-documents2.component.scss'],
})
export class UploadMultipleDocuments2Component implements OnInit {
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private uploadService: UploadService,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService
  ) {
    this.loggedInUserId = localStorage.getItem('delegateId')
      ? localStorage.getItem('delegateId')
      : this.activatedRoute.snapshot.paramMap.get('loggedInUserId');

    this.docInfo.doc = '';
    this.fnFindAppSetting();
  }

  @ViewChild('fileUpoladed', {static: false}) fileUpoladed: ElementRef;

  loggedInUserId: string;
  resultJsonObj: any = {};
  fileToUpload: File = null;
  fileName: string;
  companySlug = '';
  previousDocId = '';
  docType = ConstantHelper.DOC_TYPE_USER_UPLOADED;
  companiesList: any = [];
  companiesListLength = false;

  accessKeyId: any = '';
  secretAccessKey: any = '';

  attachmentDocment: any = '';
  files: any = [];
  practices: any;
  uploadedPracticsSettings: any;
  uploadedPracticesListSelected: any = [];
  uploadedPracticesListSelected0: any = [];
  uploadedPracticesListSelected1: any = [];
  uploadedPracticesListSelected2: any = [];
  uploadedPracticesListSelected3: any = [];
  uploadedPracticesListSelected4: any = [];

  physicans: any;

  uploadedPhysicanSettings: any;
  uploadedPhysicanListSelected: any = [];
  uploadedPhysicanListSelected0: any = [];
  uploadedPhysicanListSelected1: any = [];
  uploadedPhysicanListSelected2: any = [];
  uploadedPhysicanListSelected3: any = [];
  uploadedPhysicanListSelected4: any = [];

  uploadedDocumentsList: any = [];
  uploadedDocumentsListSelected: any = [];
  uploadedDropdownSettings: any = {};

  s3Files: any = [];
  isUploadFile = false;

  showLoader = false;

  ppData = [
    {
      practices: [],
      physicans: [],
    },
    {
      practices: [],
      physicans: [],
    },
    {
      practices: [],
      physicans: [],
    },
    {
      practices: [],
      physicans: [],
    },
    {
      practices: [],
      physicans: [],
    },
  ];

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  docInfo: any = {};

  isFileSelected = false;
  @ViewChild('fileLog', {static: false}) fileLog: ElementRef;
  @ViewChild('fileUploaded', {static: false})
  fileInput: ElementRef<HTMLInputElement>;

  tempPractice01: any = [];
  tempPractice02: any = [];
  tempPractice03: any = [];
  tempPractice04: any = [];
  tempPractice05: any = [];

  tempPhysician01: any = [];
  tempPhysician02: any = [];
  tempPhysician03: any = [];
  tempPhysician04: any = [];
  tempPhysician05: any = [];

  finalPhysician01: any = [];
  finalPhysician02: any = [];
  finalPhysician03: any = [];
  finalPhysician04: any = [];
  finalPhysician05: any = [];

  show01 = false;
  show02 = false;
  show03 = false;
  show04 = false;
  show05 = false;
  practiceInfo1: any = '';
  practiceInfo2: any = '';
  practiceInfo3: any = '';
  practiceInfo4: any = '';
  practiceInfo5: any = '';

  physicianInfo1: any = '';
  physicianInfo2: any = '';
  physicianInfo3: any = '';
  physicianInfo4: any = '';
  physicianInfo5: any = '';

  physicans1: any;
  physicans2: any;
  physicans3: any;
  physicans4: any;
  physicans5: any;

  physicianLength1 = false;
  physicianLength2 = false;
  physicianLength3 = false;
  physicianLength4 = false;
  physicianLength5 = false;

  disabled1 = false;
  disabled2 = false;
  disabled3 = false;
  disabled4 = false;
  disabled5 = false;

  previou_permission1 = [];
  previou_permission2 = [];
  previou_permission3 = [];
  previou_permission4 = [];
  previou_permission5 = [];

  previou_practice_permission1 = [];
  previou_practice_permission2 = [];
  previou_practice_permission3 = [];
  previou_practice_permission4 = [];
  previou_practice_permission5 = [];


  choosedPhysicians: any = [];
  choosedPractices: any = [];
  practiceInfos: any = [];

  isHippa: any;

  isDisableSubmitBtn = true;
  selectedDoctorsSlug: any = [];
  selectedDoctorsId: any = [];
  isHippByAdmin = false;
  isHippByAdmin1 = 4;
  isHippByAdmin2 = 4;
  isHippByAdmin3 = 4;
  isHippByAdmin4 = 4;
  isHippByAdmin5 = 4;
  profileObj: any;

  isDisabled = false;
  showNameAndFolderName1 = false;
  showNameAndFolderName2 = false;
  showNameAndFolderName3 = false;
  showNameAndFolderName4 = false;
  showNameAndFolderName5 = false;
  loggedInUserName = localStorage.getItem('loggedInUserName');

  finalRequest: any = {isUploadFile: 0};
  onBehalfOfAlertUpload = 'Y';
  // async;

  async ngAfterViewInit() {
    var item = localStorage.getItem('FullName');
    if (
      this.onBehalfOfAlertUpload === 'Y' &&
      localStorage.getItem('isDelegating') === '1'
    ) {
      // alert(
      //   "Dear User, you are doing this activity on behalf of " +
      //     localStorage.getItem("FullName")
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.ngAfterViewInit + ' ' + item
      );

      localStorage.setItem('onBehalfOfAlertUpload', 'N');
    }
  }

  ngOnInit() {
    $(function() {
      $('input[type=file]').change(function() {
        $(this).val(null);
        // const t = $(this).val();  This will show the name of file even-  afer loaded
        // const labelText = 'File : ' + t.substr(12, t.length);
        // $(this).prev('label').text(labelText);
      });
    });
    this.findCompanies();
    this.findUserDetails(this.loggedInUserId);
    this.getUserPractices(this.loggedInUserId);

    this.onBehalfOfAlertUpload = localStorage.getItem('onBehalfOfAlertUpload')
      ? localStorage.getItem('onBehalfOfAlertUpload')
      : 'Y';
      window.scroll(0,0)
  }

  getUserPractices(loggedInUserId) {
    const finalData = {
      loggedInUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findUserPractice,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.practices = apiResponse.commandResult.data.companiesList;
          this.uploadedPracticsSettings = {
            singleSelection: false,
            idField: 'company_url',
            textField: 'company',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true,
          };
          this.showLoader = false;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  fnFindAppSetting() {
    const reqData = {userId: this.loggedInUserId};
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getAppDetails,
        reqData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.accessKeyId = res.commandResult.data.awsAccessKeyId;
          this.secretAccessKey = res.commandResult.data.awsSecretAccessKey;
          this.showLoader = false;
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }

  getPhysican(company_url, i) {
    const reqData = {company_slug: company_url};
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
        ApiHelper.name_findPhysiciansByCompanySlugApi,
        reqData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.ppData[i].physicans =
            apiResponse.commandResult.data.companiesList;
          this.uploadedPhysicanSettings = {
            singleSelection: false,
            idField: 'physician_id',
            textField: 'physician',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true,
          };
          this.showLoader = false;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  findCompanies() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
        ApiHelper.name_findUserRegisteredPhysiciansApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.companiesList = apiResponse.commandResult.data.companiesList;
          this.companiesListLength = true;
          this.showLoader = false;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  async onFileSelected(event) {
    const file: File = event.target.files[0];
    const maxFileSize = 20 * 1024 * 1024;
    if (file) {
      if (file.size <= maxFileSize) {
        if (!this.validateFile(event.target.value)) {
          await this.sweetAlertHelper.alertPopUp(
            this.message.alertMessages.uploadDocComp.selectValidFile
          );
          event.target.value = null;
        } else {

          this.files = [];
          if (this.s3Files.length > 4) {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.uploadDocComp.onFileSelected
            );
            return;
          }
          if (event.target.files.length > 5) {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.uploadDocComp.onFileSelected1
            );
          } else {
            this.isUploadFile = true;
            for (let i = 0; i < event.target.files.length; i++) {
              if (
                this.uploadedDocumentsList.find(
                  (x) => x.DocumentName == event.target.files[i].name
                )
              ) {
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.uploadDocComp.onFileSelected2 +
                  event.target.files[i].name +
                  this.message.alertMessages.uploadDocComp.onFileSelected3
                );
              } else {
                this.uploadFileOnAzure(event.target.files[i] as File, this.s3Files);
                this.ppData[i].practices = this.practices;
                this.files.push(event.target.files[i]);
              }
            }
          }
        }
      } else {
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.uploadDocComp.pleaseSelectLessThan20MB
        );
        if (alertStatus) {
          this.fileInput.nativeElement.value = '';
        }
      }
    }

  }

  async uploadFile() {
    let isHippa = 0;

    this.getCheckedBoxValue();
    if (this.selectedDoctorsId.length <= 0) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.uploadFile
      );
      // alert("Please select at least one doctor");
      if (alertStatus) {
        const alertStatus = await this.sweetAlertHelper.confirmPopUpYesOrNo(
          this.message.confirmTitle,
          this.message.alertMessages.uploadDocComp.uploadDone
        );
        if(alertStatus){
          window.location.reload()
        } else {
          this.goToDashboard()
        }
      }
      return false;
    }
    const fSize = Math.round(this.fileToUpload.size / 1024);
    if (!this.validateFile(this.fileToUpload.name)) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.uploadFile1
      );

      // alert("You can only upload .png, .jpg, .jpeg. or .pdf file formats.");
      return false;
    } else if (fSize > 20000) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.uploadFile2
      );
      // alert("File too Big, please select a file less than 60mb");
    } else {
      if (this.isHippByAdmin === true) {
        isHippa = 1;
      } else {
        isHippa = 0;
      }
      // if (confirm("Are you sure you want to upload the file?"))
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.uploadDocumentComp.uploadFile
      );
      if (alertStatus) {
        this.uploadService
          .uploadFileEsign(
            this.fileToUpload,
            this.loggedInUserId,
            this.docType,
            this.selectedDoctorsId,
            this.fileToUpload.name,
            isHippa,
            this.previousDocId
          )
          .subscribe(
            async (data: any) => {
              if (data !== undefined) {
                const alertStatus =
                  await this.sweetAlertHelper.confirmPopUpYesOrNo(
                    this.message.confirmTitle,
                    this.message.confirmMessages.uploadDocumentComp.uploadFile1
                  );
                if (alertStatus) {
                  window.location.reload();
                } else {
                  this.router.navigate(['/dashboard/' + this.loggedInUserId]);
                }
              }
            },
            (error) => {
              console.error(error);
            }
          );
      } else {
        this.router.navigate(['/dashboard/' + this.loggedInUserId]);
      }
    }
  }

  async submitUploadFile() {
    // alert(this.loggedInUserId);
    const dataArray = [];
    // if(this.isUploadFile){
    this.s3Files.forEach((element, i) => {
      if (i == 0) {
        this.choosedPhysicians = this.finalPhysician01;
        this.choosedPractices = this.tempPractice01;
        this.isHippa = this.isHippByAdmin1;
        this.practiceInfos = this.practiceInfo1;
      }
      if (i == 1) {
        this.choosedPhysicians = this.finalPhysician02;
        this.choosedPractices = this.tempPractice02;
        this.isHippa = this.isHippByAdmin2;
        this.practiceInfos = this.practiceInfo2;
      }
      if (i == 2) {
        this.choosedPhysicians = this.finalPhysician03;
        this.choosedPractices = this.tempPractice03;
        this.isHippa = this.isHippByAdmin3;
        this.practiceInfos = this.practiceInfo3;
      }
      if (i == 3) {
        this.choosedPhysicians = this.finalPhysician04;
        this.choosedPractices = this.tempPractice04;
        this.isHippa = this.isHippByAdmin4;
        this.practiceInfos = this.practiceInfo4;
      }
      if (i == 4) {
        this.choosedPhysicians = this.finalPhysician05;
        this.choosedPractices = this.tempPractice05;
        this.isHippa = this.isHippByAdmin5;
        this.practiceInfos = this.practiceInfo5;
      }

      const obj = {
        RecordId: element.RecordId,
        UploadedPracticesListSelected: this.choosedPractices,
        UploadedPhysicanListSelected: this.choosedPhysicians,
        practiceInfo: this.practiceInfos,
        isHippa: this.isHippa,
        file: element.fileUrl,
        fileName: element.DocumentName,
      };
      dataArray.push(obj);
    });
    const reqData = {
      isDelegating: localStorage.getItem('isDelegating'),
      delegateId: localStorage.getItem('delegateId'),
      parentId: localStorage.getItem('parentId'),
      isUploadFile: this.isUploadFile,
      userId: this.loggedInUserId,
      data: dataArray,
      docType: this.docType,
    };


    // this.showLoader = true;
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.uploadDocumentComp.submiShareFile
    );
    if (alertStatus) {
      this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.uploadEsignMultipleFiles,
        reqData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          this.showLoader = false;
        },
        (err) => {
          console.error(err);
        }
      );
      const alertStatus = await this.sweetAlertHelper.confirmPopUpYesOrNo(this.message.confirmTitle, this.message.alertMessages.uploadDocComp.shareDone);
      if (alertStatus) {
        window.location.reload()
      } else {
        this.router.navigate(['/dashboard/' + localStorage.getItem('loggedInUserId')]);
      }
    } else {
      return false;
    }
    
  }

  async gotToDashboard() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to return to the Home Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      // this.router.navigate(['/dashboard/' + this.loggedInUserId]);
      this.router.navigate([
        '/dashboard/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
        '/1',
      ]);
    }
  }

  validateFile(name: String) {
    const maxFileSize = 20 * 1024 * 1024;
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (
      ext.toLowerCase() == 'png' ||
      ext.toLowerCase() == 'jpg' ||
      ext.toLowerCase() == 'jpeg' ||
      ext.toLowerCase() == 'pdf' ||
      ext.toLowerCase() == 'doc' ||
      ext.toLowerCase() == 'docx' ||
      ext.toLowerCase() == 'mp4'
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkCheckBoxvalue(event) {
  }

  clearSelectedDoctors() {
    this.selectedDoctorsId = [];
  }

  getCheckedBoxValue() {
    const allCheck: any = document.getElementsByClassName('docChecks');
    for (let index = 0; index < allCheck.length; index++) {
      const element = allCheck[index];
      if (element.checked) {
        this.selectedDoctorsId.push(element.value);
      }
    }
  }

  fnFindUploadedDocs(profileObj) {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: null,
      docType: ConstantHelper.DOC_TYPE_USER_UPLOADED,
      email: profileObj.Email,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_getUploadedDocumentsListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            const docList = this.resultJsonObj.commandResult.data.documentList;
            this.uploadedDocumentsList = [];
            docList.forEach((element) => {
              element.uploadedPracticesListSelected = [];
              element.uploadedPhysicanListSelected = [];
              element.uploadedPhysicanListSelected = [];
              this.uploadedDocumentsList.push(element);
            });
            this.uploadedDropdownSettings = {
              singleSelection: false,
              idField: 'RecordId',
              textField: 'DocumentName',
              selectAllText: 'Select All',
              unSelectAllText: 'UnSelect All',
              itemsShowLimit: 3,
              allowSearchFilter: true,
            };
          }
          this.showLoader = false;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: null,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.profileObj = apiResponse.commandResult.data.userInfo;
            this.fnFindUploadedDocs(this.profileObj);
          }
          this.showLoader = false;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  addPreviousDocument(previousDocId) {
    this.previousDocId = previousDocId;
  }

  async goToDashboard() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.ushfComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/dashboard/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
      ]);
    // }
  }

  async onDocumentItemSelect(item: any) {
    if (this.s3Files.length > 4) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.onDocumentItemSelect
      );
      // alert("You can not select more than 5 files");
      return;
    }
    this.isUploadFile = false;
    this.updateSelectedDocs();
  }

  async onDocumentSelectAll(items: any) {
    if (items.length > 4) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.onDocumentItemSelect
      );
      // alert("You can not select more than 5 files");
      return;
    }
    this.updateSelectedDocs();
    this.isUploadFile = false;
  }

  onPracticeItemSelect(item: any, i) {
    const testData = this.s3Files[i].uploadedPracticesListSelected;
    this.getPhysican(testData[0].company_url, i);
  }

  onPracticeSelectAll(items: any, i) {
    this.isDisabled = true;
  }

  onPracticeItemDeSelect(item: any, i) {
  }

  onDocumentItemDeSelect(item: any) {
    this.s3Files = [];
    this.updateSelectedDocs();
  }

  updateSelectedDocs() {
    this.uploadedDocumentsListSelected.forEach((element, i) => {
      this.ppData[i].practices = this.practices;
      this.uploadedDocumentsListSelected[i].uploadedPracticesListSelected = [];
      this.uploadedDocumentsListSelected[i].uploadedPhysicanListSelected = [];
      if (!this.s3Files.find((x) => x.RecordId == element.RecordId)) {
        this.s3Files.push(element);
      }
    });
  }

  onPhysicanItemSelect(item: any, i) {
    if (i == 0) {
      this.tempPhysician01 = this.s3Files[0].uploadedPhysicanListSelected;
    }
    if (i == 1) {
      this.tempPhysician02 = this.s3Files[1].uploadedPhysicanListSelected;
    }
    if (i == 2) {
      this.tempPhysician03 = this.s3Files[2].uploadedPhysicanListSelected;
    }
    if (i == 3) {
      this.tempPhysician04 = this.s3Files[3].uploadedPhysicanListSelected;
    }
    if (i == 4) {
      this.tempPhysician05 = this.s3Files[4].uploadedPhysicanListSelected;
    }
  }

  onPhysicanSelectAll(item: any, i) {
    if (i == 0) {
      item.forEach((element, i) => {
        this.tempPhysician01.push(element);
      });
    }
    if (i == 1) {
      item.forEach((element, i) => {
        this.tempPhysician02.push(element);
      });
    }
    if (i == 2) {
      item.forEach((element, i) => {
        this.tempPhysician03.push(element);
      });
    }
    if (i == 3) {
      item.forEach((element, i) => {
        this.tempPhysician04.push(element);
      });
    }
    if (i == 4) {
      item.forEach((element, i) => {
        this.tempPhysician05.push(element);
      });
    }
  }

  onPhysicanItemDeSelect(item: any, i) {
    if (item.length <= 0 && i == 0) {
      this.tempPhysician01 = [];
    }
    if (item.length <= 0 && i == 1) {
      this.tempPhysician02 = [];
    }
    if (item.length <= 0 && i == 2) {
      this.tempPhysician03 = [];
    }
    if (item.length <= 0 && i == 3) {
      this.tempPhysician04 = [];
    }
    if (item.length <= 0 && i == 4) {
      this.tempPhysician05 = [];
    }
  }

  async listData(i, file) {
    if (this.physicianInfo1.length <= 0 && this.physicianInfo2.length <= 0 && this.physicianInfo3.length <= 0 && this.physicianInfo4.length <= 0 && this.physicianInfo5.length <= 0) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.uploadDocComp.pleaseSelectPhysicianOrPractice
      );
      return false;
    }
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.uploadDocumentComp.listData
    );
    if (alertStatus) {
      if (i == 0) {
        if (this.physicianInfo1 == 'all') {
          this.tempPractice01.push(
            this.practices.find((x) => x.company_url == this.practiceInfo1)
          );
        } else {
          this.physicianInfo1.forEach((element) => {
            const physicianToAdd = this.physicans1.find((x) => x.physician_id === element);
            if (physicianToAdd && !this.finalPhysician01.some((x) => x.physician_id === physicianToAdd.physician_id)) {
              this.finalPhysician01.push(physicianToAdd);
              console.log("physicans1  ", this.finalPhysician01);
              this.tempPhysician01.push(physicianToAdd);
            }
          });
        }
        this.show01 = true;
        this.practiceInfo1 = '';
        this.physicans1 = [];
        this.physicianLength1 = false;
      }

      if (i == 1) {
        if (this.physicianInfo2 == 'all') {
          this.tempPractice02.push(
            this.practices.find((x) => x.company_url == this.practiceInfo2)
          );
        } else {
          this.physicianInfo2.forEach((element) => {
            this.finalPhysician02.push(
              this.physicans2.find((x) => x.physician_id == element)
            );
            this.tempPhysician02.push(
              this.physicans2.find((x) => x.physician_id == element)
            );
          });
        }
        this.show02 = true;
        this.practiceInfo2 = '';
        this.physicans2 = [];
        this.physicianLength2 = false;
      }

      if (i == 2) {
        if (this.physicianInfo3 == 'all') {
          this.tempPractice03.push(
            this.practices.find((x) => x.company_url == this.practiceInfo3)
          );
        } else {
          this.physicianInfo3.forEach((element) => {
            this.finalPhysician03.push(
              this.physicans3.find((x) => x.physician_id == element)
            );
            this.tempPhysician03.push(
              this.physicans3.find((x) => x.physician_id == element)
            );
          });
        }
        this.show03 = true;
        this.practiceInfo3 = '';
        this.physicans3 = [];
        this.physicianLength3 = false;
      }

      if (i == 3) {
        if (this.physicianInfo4 == 'all') {
          this.tempPractice04.push(
            this.practices.find((x) => x.company_url == this.practiceInfo4)
          );
        } else {
          this.physicianInfo4.forEach((element) => {
            this.finalPhysician04.push(
              this.physicans4.find((x) => x.physician_id == element)
            );
            this.tempPhysician04.push(
              this.physicans4.find((x) => x.physician_id == element)
            );
          });
        }
        this.show04 = true;
        this.practiceInfo4 = '';
        this.physicans4 = [];
        this.physicianLength4 = false;
      }

      if (i == 4) {
        if (this.physicianInfo5 == 'all') {
          this.tempPractice05.push(
            this.practices.find((x) => x.company_url == this.practiceInfo5)
          );
        } else {
          this.physicianInfo5.forEach((element) => {
            this.finalPhysician05.push(
              this.physicans5.find((x) => x.physician_id == element)
            );
            this.tempPhysician05.push(
              this.physicans5.find((x) => x.physician_id == element)
            );
          });
        }
        this.show05 = true;
        this.practiceInfo5 = '';
        this.physicans5 = [];
        this.physicianLength5 = false;
      }
      this.isDisableSubmitBtn = false;
      this.fileInput.nativeElement.value = null;
    }
  }

  getFileDetails(file, i) {
  }

  uploadFileOnS3(file, arrayData) {
    const contentType = file.type;
    const bucket = new S3({
      accessKeyId: ConstantHelper.AWS_ACCESS_KEY,
      secretAccessKey: ConstantHelper.AWS_SECRET_KEY,
      region: ConstantHelper.AWS_REGION,
    });
    const params = {
      Bucket: ConstantHelper.AWS_BUCKET,
      Key: file.name,
      Body: file,
      ContentType: contentType,
    };
    const finalData = [];
    return bucket.upload(params, function(err, data) {
      if (err) {
        console.error('There was an error uploading your file: ', err);
        return false;
      }
      const obj = {
        uploadedPracticesListSelected: [],
        uploadedPhysicanListSelected: [],
        RecordId: 0,
        fileUrl: data.Location,
        DocumentName: file.name,
      };

      if (!arrayData.find((x) => x.DocumentName == obj.DocumentName)) {
        arrayData.push(obj);
      }
      return obj;
    });
  }

  uploadFileOnAzure(file, arrayData) {
    this.fileToUpload = file;
    this.showLoader = true;
    return this.uploadService
      .uploadFileService(
        this.fileToUpload,
        this.loggedInUserId,
      )
      .subscribe(
        async (data: any) => {
          this.showLoader = false;
          const obj = {
            uploadedPracticesListSelected: [],
            uploadedPhysicanListSelected: [],
            RecordId: 0,
            fileUrl: data.commandResult.data.fileUrl,
            DocumentName: file.name,
          };
          if (!arrayData.find((x) => x.DocumentName == obj.DocumentName)) {
            arrayData.push(obj);
          }
          return obj;

        },
        (error) => {
          // console.log(error);
        }
      );


    // const obj = {
    //   uploadedPracticesListSelected: [],
    //   uploadedPhysicanListSelected: [],
    //   RecordId: 0,
    //   fileUrl: data.Location,
    //   DocumentName: file.name,
    // };
    //
    // if (!arrayData.find((x) => x.DocumentName == obj.DocumentName)) {
    //   arrayData.push(obj);
    // }
    // return obj;

  }

  setClass() {
    this.setStatus();
    if (this.files.length > 0) {
      return 'validField';
    } else {
      return 'invalidField';
    }
  }

  setStatus() {
    // (this.selectedItems.length > 0) ? this.requiredField = true : this.requiredField = false;
  }

  onChangePractice(companyUrl, i) {
    const reqData = {
      company_slug: companyUrl,
      loggedInUserId: this.loggedInUserId,
      i,
    };
    // this.showLoader=true;

    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findUserDoctorsByPractice,
        reqData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (i == 0) {
              if(companyUrl == 0){
                this.showNameAndFolderName1 = true;
              }
            this.physicans1 = apiResponse.commandResult.data.companiesList;
            this.disabled1 = false;
            if (Array.isArray(this.physicans1) && this.physicans1.length > 0) {
              this.physicianLength1 = true;
            } else {
              this.physicianLength1 = false;
            }
          }
          if (i == 1) {
              if(companyUrl == 0){
                this.showNameAndFolderName2 = true;
              }
            this.physicans2 = apiResponse.commandResult.data.companiesList;
            this.disabled2 = false;

            if (this.physicans2.length > 0) {
              this.physicianLength2 = true;
            }
          }
          if (i == 2) {
              if(companyUrl == 0){
                this.showNameAndFolderName3 = true;
              }
            this.physicans3 = apiResponse.commandResult.data.companiesList;
            this.disabled3 = false;

            if (this.physicans3.length > 0) {
              this.physicianLength3 = true;
            }
          }
          if (i == 3) {
              if(companyUrl == 0){
                this.showNameAndFolderName4 = true;
              }
            this.physicans4 = apiResponse.commandResult.data.companiesList;
            this.disabled4 = false;

            if (this.physicans4.length > 0) {
              this.physicianLength4 = true;
            }
          }
          if (i == 4) {
              if(companyUrl == 0){
                this.showNameAndFolderName5 = true;
              }
            this.physicans5 = apiResponse.commandResult.data.companiesList;
            this.disabled5 = false;

            if (this.physicans5.length > 0) {
              this.physicianLength5 = true;
            }
          }
          this.isDisableSubmitBtn = false;
          // this.showLoader=false;
        },
        (err) => {
        }
      );
  }

  onChangePhysician(value, j) {
    if (value == 'all' && j == 0) {
      // this.disabled1 = true;
    }
    if (value == 'all' && j == 1) {
      // this.disabled2 = true;
    }
    if (value == 'all' && j == 2) {
      // this.disabled3 = true;
    }
    if (value == 'all' && j == 3) {
      // this.disabled4 = true;
    }
    if (value == 'all' && j == 4) {
      // this.disabled5 = true;
    }

    //disable1
  }

  fndPremission(RecordId, i) {
    if (
      i == 0 &&
      this.uploadedDocumentsList.find((x) => x.RecordId == RecordId)
    ) {
      this.previou_permission1 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previou_permissions;
      this.previou_practice_permission1 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previouse_practice_permissions;
      // console.log(this.previou_practice_permission1);
    }
    if (
      i == 1 &&
      this.uploadedDocumentsList.find((x) => x.RecordId == RecordId)
    ) {
      this.previou_permission2 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previou_permissions;
      this.previou_practice_permission2 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previouse_practice_permissions;
      // console.log(this.previou_practice_permission2);
    }
    if (
      i == 2 &&
      this.uploadedDocumentsList.find((x) => x.RecordId == RecordId)
    ) {
      this.previou_permission3 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previou_permissions;
      this.previou_practice_permission3 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previouse_practice_permissions;
      // console.log(this.previou_practice_permission3);
    }
    if (
      i == 3 &&
      this.uploadedDocumentsList.find((x) => x.RecordId == RecordId)
    ) {
      this.previou_permission4 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previou_permissions;
      this.previou_practice_permission4 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previouse_practice_permissions;
      // console.log(this.previou_permission4);
    }
    if (
      i == 4 &&
      this.uploadedDocumentsList.find((x) => x.RecordId == RecordId)
    ) {
      this.previou_permission5 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previou_permissions;
      this.previou_practice_permission5 = this.uploadedDocumentsList.find(
        (x) => x.RecordId == RecordId
      ).previouse_practice_permissions;
      // console.log(this.previou_permission5);
    }
  }

  fnChangeDocumentPhysicianNameFormat(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      let nameArr = e.physician.split('(');
      e.physician =
        this.userService.getUS_FromatName(
          nameArr[0].replace(/^\s+|\s+$/g, '')
        ) +
        ' (' +
        nameArr[1];
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(this.userService.dynamicSort('physician'));
  }

removeDocument(recordId) {
  this.isDisableSubmitBtn = true;
  const index = this.s3Files.findIndex((x) => x.RecordId === recordId);
  if (index !== -1) {
    this.s3Files.splice(index, 1);
    if (index === 0) {
      this.practiceInfo1 = '';
      this.physicans1 = [];
      this.physicianLength1 = false;
      this.previou_permission1 = null;
      this.previou_practice_permission1 = null;
    } else if (index === 1) {
 
    } else if (index === 2) {
     
    }
}
this.uploadedDocumentsListSelected = [];
  }

}
