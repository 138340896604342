import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../environments/environment";
import { ApiHelper } from "./../_helpers/api.helper";
import { CommonHelper } from "./../_helpers/common.helper";
import { ConstantHelper } from "./../_helpers/constant.helper";
import { AppConfig } from "./../../app/app.config";
import { UserService } from "./../_services/user.service";
import { Message } from "../_locale/messages";
import { SweetAlertHelper } from "../_helpers/sweet.alert.helper";
import { Location } from '@angular/common';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  loggedInUserName: any;
  loggedInUserEmail: any;
  loggedInUserAge: any;
  loggedInUserDOB: any;
  currentDate: any;
  isMinor: any;
  demographicId: string;
  loggedInUserId: any;
  loggedInUserRoleId: any;
  showUserLink = false;
  resultJsonObj: any = {};
  // totalPendingCount: any;
  // hipaaPendingCount: any;
  // documentReviewPendingCount: any;
  dataArray: { invitation: number }[];
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;
  dashBoardItes = false;
  ushfItems = false;
  uploadDocItems = false;
  reviewDocItems = false;
  regDoctorItems = false;
  addDoctorItems = false;
  emailDoctorItems = false;
  emailLogItems = false;
  emailLogResendItems = false;
  demographicsItems = false;
  parentMinorItems = false;
  parentMinorActivityLogsItems = false;
  exprtPhyOpinionLogsItems = false;
  exprtPhyOpinionItems = false;
  showWhatCanIDo = false;
  logsList: any;
  practiceDocCount = 0;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService,
    private location: Location
  ) {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.findUserDetails();
    // tslint:disable-next-line: max-line-length
    this.loggedInUserName = localStorage.getItem("loggedInUserName")
      ? localStorage.getItem("loggedInUserName")
      : localStorage.getItem("loggedInUserEmail");
    this.loggedInUserRoleId = localStorage.getItem("loggedInUserRoleId");
    this.demographicId = localStorage.getItem("demographicId");
    this.getCurrentUrl();
    this.showItemList();
    localStorage.setItem("currentURL", this.location.path())
  }

  ngOnInit() {
    $(".dropmenu-link").hover(function () {
      const isHovered = $(this).is(":hover");
      if (isHovered) {
        $(this).children("ul").stop().slideDown(300);
      } else {
        $(this).children("ul").stop().slideUp(300);
      }
    });

    // this.findHipaaShareRequests();
    this.findUserDoctors();
    this.getLoggedInUserAge();
    // this.documentReviewPendingCount = sessionStorage.getItem("documentReviewPendingCount");
    // this.hipaaPendingCount = sessionStorage.getItem("hipaaPendingCount");
    // this.totalPendingCount = +this.hipaaPendingCount + +this.documentReviewPendingCount;
    this.isMinor = localStorage.getItem("isMinor");
    this.isMinor = parseInt(this.isMinor);
  }


  async logout() {
    // if (confirm('PLEASE CONFIRM: Are you sure, you want to close your session?'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.docHeaderComp.logout
    );

    if (alertStatus) {
      localStorage.clear();
      this.router.navigate(["/"]);
    }
  }

  async goToDashboard() {
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to go to Home page?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.headerComp.goToDashboard
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }
  findUserDetails() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          this.loggedInUserName =
            this.resultJsonObj.commandResult.data.userInfo.FullName;
          this.loggedInUserDOB = localStorage.setItem(
            "loggedInUserDOB",
            this.resultJsonObj.commandResult.data.userInfo.DOB
          );
          this.loggedInUserEmail =
            this.resultJsonObj.commandResult.data.userInfo.Email;
          localStorage.setItem(
            "loggedInUserId",
            this.resultJsonObj.commandResult.data.userInfo.RecordId
          );
          localStorage.setItem(
            "loggedInUserName",
            this.resultJsonObj.commandResult.data.userInfo.FullName
          );
          localStorage.setItem(
            "loggedInUserEmail",
            this.resultJsonObj.commandResult.data.userInfo.Email
          );
          localStorage.setItem(
            "loggedInUserRoleId",
            this.resultJsonObj.commandResult.data.userInfo.UserRoleId
          );
          localStorage.setItem("loggedInUserComapnyId", "1");
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToPractices() {
    this.router.navigate(["/practices/" + this.loggedInUserId]);
  }

  goToContactUs() {
    this.router.navigate(["/contact-us/" + this.loggedInUserId]);
  }

  goToChangePassword() {
    this.router.navigate(["/change-password/" + this.loggedInUserId]);
  }

  goToHIPAAForms() {
    this.router.navigate(["/" + "ushf" + "/" + this.loggedInUserId]);
  }

  goToReviewAndSignDoc() {
    this.router.navigate([
      "/" + "registered-doctors" + "/" + this.loggedInUserId,
    ]);
  }

  goToReviewDoc() {
    this.router.navigate([
      "/" + "doc-review" + "/" + this.loggedInUserId + "/" + this.demographicId,
    ]);
  }

  goToExpertPhysicianOpinion() {
    this.router.navigate([
      "/" + "expert-physician-opinion-log" + "/" + this.loggedInUserId,
    ]);
  }

  getLoggedInUserAge() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    this.currentDate = `${year}-${month}-${day}`;
    this.loggedInUserDOB = localStorage.getItem("loggedInUserDOB").split("-");
    this.loggedInUserAge = year - +this.loggedInUserDOB[0];
  }

  showItemList() {
    if(this.getCurrentUrl().includes('/dashboard/')){
      this.showWhatCanIDo = true;
      this.dashBoardItes = !this.dashBoardItes;
    }else if (this.getCurrentUrl().includes('/ushf/')){
      this.showWhatCanIDo = true;
      this.ushfItems = !this.ushfItems;
    }else if (this.getCurrentUrl().includes('/upload-doc/')){
      this.showWhatCanIDo = true;
      this.uploadDocItems = !this.uploadDocItems;
    }else if (this.getCurrentUrl().includes('/doc-review/')){
      this.showWhatCanIDo = true;
      this.reviewDocItems = !this.reviewDocItems;
    }else if (this.getCurrentUrl().includes('/registered-doctors/')){
      this.showWhatCanIDo = true;
      this.regDoctorItems = !this.regDoctorItems;
    } else if (this.getCurrentUrl().includes('/choose-doctors/')){
      this.showWhatCanIDo = true;
      this.addDoctorItems = !this.addDoctorItems;
    } else if (this.getCurrentUrl().includes('/send-doc/')){
      this.showWhatCanIDo = true;
      this.emailDoctorItems = !this.emailDoctorItems;
    } else if (this.getCurrentUrl().includes('/email-log/')){
      this.showWhatCanIDo = true;
      this.emailLogItems = !this.emailLogItems;
    } else if (this.getCurrentUrl().includes('/resend-docs/')){
      this.showWhatCanIDo = true;
      this.emailLogResendItems = !this.emailLogResendItems;
    } else if (this.getCurrentUrl().includes('/demographicsform/')){
      this.showWhatCanIDo = true;
      this.demographicsItems = !this.demographicsItems;
    } else if (this.getCurrentUrl().includes('/parent-minor-relation/')){
      this.showWhatCanIDo = true;
      this.parentMinorItems = !this.parentMinorItems;
    } else if (this.getCurrentUrl().includes('/minor-activity-log/')){
      this.showWhatCanIDo = true;
      this.parentMinorActivityLogsItems = !this.parentMinorActivityLogsItems;
    } else if (this.getCurrentUrl().includes('/expert-physician-opinion-log/')){
      this.showWhatCanIDo = true;
      this.exprtPhyOpinionLogsItems = !this.exprtPhyOpinionLogsItems;
    } else if (this.getCurrentUrl().includes('/expert-physician-opinion/')){
      this.showWhatCanIDo = true;
      this.exprtPhyOpinionItems = !this.exprtPhyOpinionItems;
    }
  }

  getCurrentUrl(): string {
    return this.location.path();
  }

  openPatientHelpDocument() {
    if(this.isMinor == 0){
      window.open('https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/help_docs/AHS_Patient_Site_Instructions.pdf', '_blank');
    } else {
      window.open('https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/help_docs/AHS_Minor_Site_Instructions.pdf', '_blank');
    }
  }

  openMinorHelpDocument() {
    window.open(
      'https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/help_docs/AHS_Minor_Site_Instructions.pdf', '_blank');
  }

  changePassword(){
    this.router.navigate(["/" + "change-password" + "/" + this.loggedInUserId]);
  }

  findUserDoctors() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findUserDoctors,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {

          this.logsList = apiResponse.commandResult.data.companiesList
            ? apiResponse.commandResult.data.companiesList
            : [];

            this.logsList.map((id) => {
              this.practiceDocCount = +id.invitation+this.practiceDocCount
            })

            // alert(this.practiceDocCount)
            if(this.practiceDocCount > 0){
              sessionStorage.setItem("practiceDocCount", '' + this.practiceDocCount)
            }
        },
        (err) => {
          // console.log(err);
        }
      );
  }
}
