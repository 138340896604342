import { Component, OnInit } from "@angular/core";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/messages";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-thumb-mobile",
  templateUrl: "./thumb-mobile.component.html",
  styleUrls: ["./thumb-mobile.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class ThumbMobileComponent implements OnInit {
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  hidePdfPopUp: boolean = false;
  showDemographicModal: boolean = false;
  showHippaModal: boolean = false;
  showHippaDocModal: boolean = false;
  showDisclaimerDocModal: boolean = false;
  showDisclaimerModal: boolean = false;

  demographicUrl: SafeResourceUrl;
  hippaUrl: SafeResourceUrl;
  disclaimerUrl: SafeResourceUrl;
  ahsDisclaimerPdf: SafeResourceUrl;
  ahsHippaPdf: SafeResourceUrl;

  showDocThumbnails: boolean = true;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get("demographicId");
  }

  showDemographicForm() {
    // alert('showDemographicForm');
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.generateDemographicPdf,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.success == 1) {
            // console.log(this.resultJsonObj.commandResult.data);

            this.demographicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );

            this.showDemographicModal = true;
            // console.log(apiResponse);
          } else {
            // alert("oops! error occured, please try later");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.onSubmit
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  showHippaForm() {
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
    };

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_generateHippaRealeasPdfApi,
        finalData,
        options
      )
      .subscribe(
       async (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.success == 1) {
            this.hippaUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );
            this.showHippaModal = true;
          } else {
            // alert("oops! error occured, please try later");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.onSubmit
            );

          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  showDisclaimerForm() {
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
    };

    return this.http
      .post(
        this.appConfig.getPhpUrl() +
          ApiHelper.php_generateWaiverDisclaimerPdfApi,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.success == 1) {
            // console.log(apiResponse);
            this.disclaimerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );
            // alert("demographic form generated");
            this.showDisclaimerModal = true;
          } else {
            // alert("oops! error occured, please try later");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.onSubmit
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  signOff() {
    this.showDocThumbnails = false;

    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
    };

    return this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.generateDemographicPdf,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.http
            .post(
              this.appConfig.getPhpUrl() +
                ApiHelper.php_generateHippaRealeasPdfApi,
              finalData,
              options
            )
            .subscribe(
              (apiResponse) => {
                this.http
                  .post(
                    this.appConfig.getPhpUrl() +
                      ApiHelper.php_generateWaiverDisclaimerPdfApi,
                    finalData,
                    options
                  )
                  .subscribe(
                    (apiResponse) => {
                      this.http
                        .post(
                          this.appConfig.getPhpUrl() +
                            ApiHelper.php_sendSignOffMailApi,
                          finalData,
                          options
                        )
                        .subscribe(
                          (apiResponse) => {
                            this.resultJsonObj = apiResponse;
                            // console.log(this.resultJsonObj);

                            this.http
                              .post(
                                this.appConfig.getPhpAhsUrl() +
                                  ApiHelper.ahs_uploadFile2ElemrexApi,
                                finalData,
                                options
                              )
                              .subscribe(
                                (apiResponse) => {
                                  this.resultJsonObj = apiResponse;
                                  // console.log(this.resultJsonObj);

                                  // this.router.navigate(["/thankyou/" + this.loggedInUserId]);
                                },
                                (err) => {
                                  // console.log(err);
                                }
                              );
                          },
                          (err) => {
                            // console.log(err);
                          }
                        );
                    },
                    (err) => {
                      // console.log(err);
                    }
                  );
              },
              (err) => {
                // console.log(err);
              }
            );
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  closeDemographicModal() {
    this.showDemographicModal = false;
  }

  closeHippaModal() {
    this.showHippaModal = false;
  }

  closeDisclaimerModal() {
    this.showDisclaimerModal = false;
  }

  editDemographicForm4() {
    this.router.navigate([
      "/edit-demographic-4/" + this.loggedInUserId + "/" + this.demographicId,
    ]);
  }

  goToDemographicForm() {
    this.router.navigate(["/demographicsform/" + this.loggedInUserId]);
  }

  showHippaDoc() {
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
    };

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_showHippaDocApi,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.success == 1) {
            this.ahsHippaPdf = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );
            this.showHippaDocModal = true;
          } else {
            // alert("oops! error occurred, please try later");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.onSubmit
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  showDisclaimerDoc() {
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
    };

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_showDisclaimerDocApi,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.success == 1) {
            this.ahsDisclaimerPdf =
              this.sanitizer.bypassSecurityTrustResourceUrl(
                this.appConfig.getDisclaimerPdf()
              );

            this.showDisclaimerDocModal = true;
          } else {
            // alert("oops! error occurred, please try later");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.onSubmit
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  closeHippaDocModal() {
    this.showHippaDocModal = false;
  }

  closeDisclaimerDocModal() {
    this.showDisclaimerDocModal = false;
  }

  editSignature() {
    this.router.navigate([
      "/signaturewaiver/" + this.loggedInUserId + "/" + this.demographicId,
    ]);
  }

  ////////////////////////////////////////////////////////
}
