import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { UserService } from "../../_services/user.service";
import { ArrayHelper } from "./../../_helpers/array.helper";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";

@Component({
  selector: "app-ushf",
  templateUrl: "./ushf.component.html",
  styleUrls: ["./ushf.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class UshfComponent implements OnInit {
  
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  docInfo: any = {};
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  showLoader = false;

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  statesArre: any = [];
  stateSelectDisabledOption = true;
  citySelectDisabledOption = true;
  physicianSelectDisabledOption = true;
  cityLength = false;
  noCityLength = false;
  citiesList: any;
  physicianLength = false;
  noPhysicianLength = false;
  physiciansList: any = [];
  selectedPhysician: any = {};
  hippaAvailable: any;
  selectedDocUrl: any;
  selectedStateCode: any;
  isProgrammed = false;
  selectedHippaState: any;
  showInfoModal = false;
  showInfoModalDelete = false;
  requestsArr: any = [];
  requestedByArr: any = [];
  isRequest = false;
  isEmail = 1;
  selectedRequestId: any;
  isPracticePhy = "0";
  showComfirmationModal = false;
  searchStateList: any = ArrayHelper.STATE_ARRAY;
  doctorTableArr: any = [];
  doctorTableArrTemp: any = [];
  isSearched = false;
  practices: any = [];
  showSearchPhysicianModal = false;
  physicianSearchObj: any = {};
  hasHippaFile = false;
  selectedStateName: any;
  selectedCityName: any;
  selectedPhysicianId: any;
  selectedEmail: any;
  hasPhysicianEmail = 1;
  message = Message;
  physicianCount = 1;
  searchType: any = "0";
  checkAltEmail = false;
  onBehalfOfAlertUshf = "Y";

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private uploadService: UploadService,
    public userService: UserService,
    public sweetAlertHelper: SweetAlertHelper
  ) {
    this.docInfo.state = "";
    this.docInfo.city = "";
    this.docInfo.physicianName = "";
    this.docInfo.hipaaDoctor = "";
    this.docInfo.requestedBy = "";
  }

  setSearchTypeVal(e) {
    this.searchType = e.target.value;
    this.selectedPhysician = {};
    this.isPracticePhy = "0";
    this.docInfo.state = "";
    this.docInfo.city = "";
  }

  async ngAfterViewInit() {
    let item = "";
    item = localStorage.getItem("FullName");
    if (
      this.onBehalfOfAlertUshf === "Y" &&
      localStorage.getItem("isDelegating") === "1"
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.ngAfterViewInit + " " + item
      );

      // alert(
      //   "Dear User, you are doing this activity on behalf of " +
      //     localStorage.getItem("FullName")
      // );

      localStorage.setItem("onBehalfOfAlertUshf", "N");
    }
  }

  ngOnInit() {
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.findPhysicianCities();
    this.findHipaaShareRequests();
    this.findHipaaShareRequestsBypatient();
    this.onBehalfOfAlertUshf = localStorage.getItem("onBehalfOfAlertUshf")
      ? localStorage.getItem("onBehalfOfAlertUshf")
      : "Y";
    window.scroll(0,0)
  }

  findHipaaShareRequestsBypatient() {
    const finalData = {
      patientId: this.loggedInUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl06() +
          ApiHelper.findHippaShareRequestByPatientId,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.showLoader = false;
          this.requestsArr = res.commandResult.data.requests;
          this.findHipaaShareRequests();

          if (res.commandResult.data.requests.length > 0) {
            this.searchType = 1;
          } else {
            this.searchType = 2;
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }

  findHipaaShareRequests() {
    const finalData = {
      patientId: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findHippaShareRequestLog,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.requestsArr = res.commandResult.data.requests;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  fnFindSenders(physicianNpi) {
    this.requestedByArr = this.fnChangePhysicianNameForma1t(
      this.requestsArr.find((e) => e.PhysicianNpi == physicianNpi).Senders
    );
    this.isEmail = 2;
  }

  updatePhysicianInfo2(physicianNpi) {
    this.physicianSelectDisabledOption = false;
    this.selectedPhysician = this.requestsArr.find(
      (e) => e.PhysicianNpi == physicianNpi
    );
    this.docInfo.state = this.selectedPhysician.PhysicianStateName;
    this.docInfo.city = this.selectedPhysician.PhysicianCity;
    this.isRequest = true;

    this.selectedPhysician.physicianEmailMasked = this.selectedPhysician
      .PhysicianEmail
      ? this.commonHelper.maskEmail(this.selectedPhysician.PhysicianEmail)
      : "";

    this.selectedStateCode = this.selectedPhysician.PhysicianStateCode;

    this.onChangeUserState(this.selectedPhysician.PhysicianStateCode);
    if (
      this.selectedPhysician.PhysicianStateCode == "NJ" ||
      this.selectedPhysician.PhysicianStateCode == "NY" ||
      this.selectedPhysician.PhysicianStateCode == "FL" ||
      this.selectedPhysician.PhysicianStateCode == "PA" ||
      this.selectedPhysician.PhysicianStateCode == "CT" ||
      this.selectedPhysician.PhysicianStateCode == "OH" ||
      this.selectedPhysician.PhysicianStateCode == "CO" ||
      this.selectedPhysician.PhysicianStateCode == "CA" ||
      this.selectedPhysician.PhysicianStateCode == "AL" ||
      this.selectedPhysician.PhysicianStateCode == "AZ" ||
      this.selectedPhysician.PhysicianStateCode == "AR" ||
      this.selectedPhysician.PhysicianStateCode == "DE" ||
      this.selectedPhysician.PhysicianStateCode == "GA" ||
      this.selectedPhysician.PhysicianStateCode == "HI" ||
      this.selectedPhysician.PhysicianStateCode == "ID" ||
      this.selectedPhysician.PhysicianStateCode == "IL" ||
      this.selectedPhysician.PhysicianStateCode == "AK" ||
      this.selectedPhysician.PhysicianStateCode == "TX" ||
      this.selectedPhysician.PhysicianStateCode == "SD" ||
      this.selectedPhysician.PhysicianStateCode == "ND" ||
      this.selectedPhysician.PhysicianStateCode == "VA" ||
      this.selectedPhysician.PhysicianStateCode == "VT" ||
      this.selectedPhysician.PhysicianStateCode == "KY" ||
      this.selectedPhysician.PhysicianStateCode == "WI" ||
      this.selectedPhysician.PhysicianStateCode == "WV" ||
      this.selectedPhysician.PhysicianStateCode == "TN" ||
      this.selectedPhysician.PhysicianStateCode == "LA" ||
      this.selectedPhysician.PhysicianStateCode == "MD" ||
      this.selectedPhysician.PhysicianStateCode == "MN" ||
      this.selectedPhysician.PhysicianStateCode == "MO" ||
      this.selectedPhysician.PhysicianStateCode == "MS" ||
      this.selectedPhysician.PhysicianStateCode == "NV" ||
      this.selectedPhysician.PhysicianStateCode == "OK" ||
      this.selectedPhysician.PhysicianStateCode == "WA" ||
      this.selectedPhysician.PhysicianStateCode == "IA" ||
      this.selectedPhysician.PhysicianStateCode == "NH" ||
      this.selectedPhysician.PhysicianStateCode == "KS" ||
      this.selectedPhysician.PhysicianStateCode == "OR" ||
      this.selectedPhysician.PhysicianStateCode == "NE" ||
      this.selectedPhysician.PhysicianStateCode == "MT" ||
      this.selectedPhysician.PhysicianStateCode == "NC" ||
      this.selectedPhysician.PhysicianStateCode == "SC" ||
      this.selectedPhysician.PhysicianStateCode == "IN" ||
      this.selectedPhysician.PhysicianStateCode == "ME" ||
      this.selectedPhysician.PhysicianStateCode == "RI" ||
      this.selectedPhysician.PhysicianStateCode == "UT" ||
      this.selectedPhysician.PhysicianStateCode == "NM" ||
      this.selectedPhysician.PhysicianStateCode == "MA"
    ) {
      this.isProgrammed = true;
    } else {
      this.isProgrammed = false;
    }
    localStorage.setItem(
      "providerName",
      this.userService.getRefromatName(this.selectedPhysician.PhysicianName)
    );
    localStorage.setItem(
      "providerOfficeAddress",
      this.selectedPhysician.PhysicianOfficeAddress
    );
    localStorage.setItem("providerZipCode", this.selectedPhysician.zip_code);
    localStorage.setItem(
      "providerPhone",
      this.selectedPhysician.PhysicianOfficePhone
        ? this.selectedPhysician.PhysicianOfficePhone
        : ""
    );
    localStorage.setItem(
      "providerEmail",
      this.selectedPhysician.PhysicianEmail
    );
    localStorage.setItem(
      "providerPhysicianName",
      this.userService.getRefromatName(this.selectedPhysician.PhysicianName)
    );
    localStorage.setItem(
      "providerPhysicianNipNo",
      this.selectedPhysician.PhysicianNipNo
    );
  }

  fnChangePhysicianNameForma1t(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.SenderName = this.userService.getUS_FromatName(e.SenderName);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(this.userService.dynamicSort("SenderName"));
  }

  findPhysicianCities() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findPhysicianCitiesApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.statesArre = apiResponse.commandResult.data.statesList;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  async onReset() {
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.ushfComp.onReset
    // );
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘OK’: You will be returned to the ‘Home Page’."
    //   )
    // )
    // if (alertStatus) {
      this.router.navigate([
        "/dashboard/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    // }
  }

  async onSubmit() {
    sessionStorage.setItem('phyAltEmail', this.selectedPhysician.altEmail);
    if (this.isEmail == 1 && this.selectedStateCode) {
      if (this.searchType == 2 && !this.selectedPhysician.altEmail) {
          await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.ushfComp.onSubmit
          );
          return false;
      }
      if (this.selectedPhysician.altEmail != this.selectedPhysician.cnfAltEmail) {
          await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.ushfComp.onsubmit1
          );
          return false;
      }
  }

    if (this.searchType == 1 && !this.docInfo.hipaaDoctor) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.ushfComp.onSubmit2
      );
      // alert("Please select HIPAA For Doctor.");
      return false;
    }
    if (this.searchType == 1 && !this.selectedRequestId) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.ushfComp.onSubmit3
      );
      // alert("Please select Requested By Whom.");
      return false;
    }
    // return;
    if (this.isEmail == 1 && this.selectedStateCode == "NJ") {
      this.router.navigate([
        "/hippa-nj/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "NY") {
      this.router.navigate([
        "/hippa-ny/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "FL") {
      this.router.navigate([
        "/hippa-fl/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "PA") {
      this.router.navigate([
        "/hippa-pa/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "CT") {
      this.router.navigate([
        "/hippa-ct/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "OH") {
      this.router.navigate([
        "/hippa-oh/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "CO") {
      this.router.navigate([
        "/hippa-co/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "CA") {
      this.router.navigate([
        "/hippa-ca/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "AL") {
      this.router.navigate([
        "/hippa-al/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "AZ") {
      this.router.navigate([
        "/hippa-az/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "AR") {
      this.router.navigate([
        "/hippa-ar/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "DE") {
      this.router.navigate([
        "/hippa-de/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "GA") {
      this.router.navigate([
        "/hippa-ga/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "HI") {
      this.router.navigate([
        "/hippa-hi/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "ID") {
      this.router.navigate([
        "/hippa-id/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "IL") {
      this.router.navigate([
        "/hippa-il/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "AK") {
      this.router.navigate([
        "/hippa-ak/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "TX") {
      this.router.navigate([
        "/hippa-tx/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "SD") {
      this.router.navigate([
        "/hippa-sd/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "ND") {
      this.router.navigate([
        "/hippa-nd/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "VA") {
      this.router.navigate([
        "/hippa-va/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "VT") {
      this.router.navigate([
        "/hippa-vt/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "KY") {
      this.router.navigate([
        "/hippa-ky/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "WI") {
      this.router.navigate([
        "/hippa-wi/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "WV") {
      this.router.navigate([
        "/hippa-wv/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "TN") {
      this.router.navigate([
        "/hippa-tn/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "LA") {
      this.router.navigate([
        "/hippa-la/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "MD") {
      this.router.navigate([
        "/hippa-md/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "MN") {
      this.router.navigate([
        "/hippa-mn/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "MO") {
      this.router.navigate([
        "/hippa-mo/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "MS") {
      this.router.navigate([
        "/hippa-ms/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "NV") {
      this.router.navigate([
        "/hippa-nv/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "OK") {
      this.router.navigate([
        "/hippa-ok/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "WA") {
      this.router.navigate([
        "/hippa-wa/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "IA") {
      this.router.navigate([
        "/hippa-ia/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "NH") {
      this.router.navigate([
        "/hippa-nh/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "KS") {
      this.router.navigate([
        "/hippa-ks/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "OR") {
      this.router.navigate([
        "/hippa-or/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "NE") {
      this.router.navigate([
        "/hippa-ne/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "MT") {
      this.router.navigate([
        "/hippa-mt/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "NC") {
      this.router.navigate([
        "/hippa-nc/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "SC") {
      this.router.navigate([
        "/hippa-sc/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "IN") {
      this.router.navigate([
        "/hippa-in/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "ME") {
      this.router.navigate([
        "/hippa-me/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "RI") {
      this.router.navigate([
        "/hippa-ri/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "UT") {
      this.router.navigate([
        "/hippa-ut/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "NM") {
      this.router.navigate([
        "/hippa-nm/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 1 && this.selectedStateCode == "MA") {
      this.router.navigate([
        "/hippa-ma/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "NJ") {
      this.router.navigate([
        "/hippa-nj/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "NY") {
      this.router.navigate([
        "/hippa-ny/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "FL") {
      this.router.navigate([
        "/hippa-fl/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "PA") {
      this.router.navigate([
        "/hippa-pa/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "CT") {
      this.router.navigate([
        "/hippa-ct/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "OH") {
      this.router.navigate([
        "/hippa-oh/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "CO") {
      this.router.navigate([
        "/hippa-co/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "CA") {
      this.router.navigate([
        "/hippa-ca/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "AL") {
      this.router.navigate([
        "/hippa-al/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "AZ") {
      this.router.navigate([
        "/hippa-az/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "AR") {
      this.router.navigate([
        "/hippa-ar/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "DE") {
      this.router.navigate([
        "/hippa-de/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "GA") {
      this.router.navigate([
        "/hippa-ga/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "HI") {
      this.router.navigate([
        "/hippa-hi/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "ID") {
      this.router.navigate([
        "/hippa-id/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "IL") {
      this.router.navigate([
        "/hippa-il/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "AK") {
      this.router.navigate([
        "/hippa-ak/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "TX") {
      this.router.navigate([
        "/hippa-tx/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "SD") {
      this.router.navigate([
        "/hippa-sd/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "ND") {
      this.router.navigate([
        "/hippa-nd/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "VA") {
      this.router.navigate([
        "/hippa-va/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "VT") {
      this.router.navigate([
        "/hippa-vt/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "KY") {
      this.router.navigate([
        "/hippa-ky/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "WI") {
      this.router.navigate([
        "/hippa-wi/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "WV") {
      this.router.navigate([
        "/hippa-wv/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "TN") {
      this.router.navigate([
        "/hippa-tn/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "LA") {
      this.router.navigate([
        "/hippa-la/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "MD") {
      this.router.navigate([
        "/hippa-md/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "MN") {
      this.router.navigate([
        "/hippa-mn/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "MO") {
      this.router.navigate([
        "/hippa-mo/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "MS") {
      this.router.navigate([
        "/hippa-ms/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "NV") {
      this.router.navigate([
        "/hippa-nv/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "OK") {
      this.router.navigate([
        "/hippa-ok/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "WA") {
      this.router.navigate([
        "/hippa-wa/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "IA") {
      this.router.navigate([
        "/hippa-ia/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "NH") {
      this.router.navigate([
        "/hippa-nh/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "KS") {
      this.router.navigate([
        "/hippa-ks/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "OR") {
      this.router.navigate([
        "/hippa-or/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "NE") {
      this.router.navigate([
        "/hippa-ne/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "MT") {
      this.router.navigate([
        "/hippa-mt/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "NC") {
      this.router.navigate([
        "/hippa-nc/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "SC") {
      this.router.navigate([
        "/hippa-sc/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "IN") {
      this.router.navigate([
        "/hippa-in/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "ME") {
      this.router.navigate([
        "/hippa-me/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "RI") {
      this.router.navigate([
        "/hippa-ri/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "UT") {
      this.router.navigate([
        "/hippa-ut/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "NM") {
      this.router.navigate([
        "/hippa-nm/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else if (this.isEmail == 2 && this.selectedStateCode == "MA") {
      this.router.navigate([
        "/hippa-ma/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.isEmail +
          "/" +
          this.selectedRequestId,
      ]);
    } else {
      if(this.selectedStateCode == undefined){
        await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.ushfComp.selectPhysician
        );
        return
      }
      if (this.selectedDocUrl) {
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.ushfComp.onSubmit4
        );

        // alert("You need to download HIPAA form manually email to the doctor.");
        window.open(this.selectedDocUrl);
        window.location.reload();
      } else {
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.ushfComp.onSubmit5
        );
        // alert("No HIPAA form found for the state.");
      }
    }
  }

  findCitiesByStateCode(stateCode) {
    this.showInfoModal = true;

    this.selectedStateCode = stateCode;
    this.docInfo.city = "";
    this.docInfo.physicianName = "";
    this.selectedPhysician = {};
    localStorage.setItem(
      "selectedHippaState",
      this.statesArre.find((e) => e.StateCode == stateCode).State
    );
    this.stateSelectDisabledOption = false;
    this.citiesList = [];
    if (
      stateCode == "NJ" ||
      stateCode == "NY" ||
      stateCode == "FL" ||
      stateCode == "PA" ||
      stateCode == "CT" ||
      stateCode == "OH" ||
      stateCode == "CO" ||
      stateCode == "CA" ||
      stateCode == "AL" ||
      stateCode == "AZ" ||
      stateCode == "AR" ||
      stateCode == "DE" ||
      stateCode == "GA" ||
      stateCode == "HI" ||
      stateCode == "ID" ||
      stateCode == "IL" ||
      stateCode == "AK" ||
      stateCode == "TX" ||
      stateCode == "SD" ||
      stateCode == "ND" ||
      stateCode == "VA" ||
      stateCode == "VT" ||
      stateCode == "KY" ||
      stateCode == "WI" ||
      stateCode == "WV" ||
      stateCode == "TN" ||
      stateCode == "LA" ||
      stateCode == "MD" ||
      stateCode == "MN" ||
      stateCode == "MO" ||
      stateCode == "MS" ||
      stateCode == "NV" ||
      stateCode == "OK" ||
      stateCode == "WA" ||
      stateCode == "IA" ||
      stateCode == "NH" ||
      stateCode == "KS" ||
      stateCode == "NE" ||
      stateCode == "OR" ||
      stateCode == "MT" ||
      stateCode == "NC" ||
      stateCode == "SC" ||
      stateCode == "IN" ||
      stateCode == "ME" ||
      stateCode == "RI" ||
      stateCode == "UT" ||
      stateCode == "NM" ||
      stateCode == "MA"
    ) {
      this.isProgrammed = true;
    } else {
      this.isProgrammed = false;
    }

    const finalData = { stateCode };
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.citySelectDisabledOption = true;
          if (apiResponse.commandResult.status == 1) {
            if (apiResponse.commandResult.data.cityList.length > 0) {
              this.cityLength = true;
              this.citiesList = apiResponse.commandResult.data.cityList;
            } else {
              this.noCityLength = true;
            }
          } else {
            this.noCityLength = true;
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }

  findPhysicianByStateCode(cityName) {
    this.docInfo.city = cityName;
    this.docInfo.physicianName = "";
    this.selectedPhysician = {};
    this.citySelectDisabledOption = false;
    // tslint:disable-next-line: object-literal-shorthand
    const finalDataPhysician = { cityName: cityName };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          if (apiResponsePhysician.commandResult.status == 1) {
            this.physicianSelectDisabledOption = true;
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.physicianLength = true;
              // this.physiciansList = apiResponsePhysician.commandResult.data.physiciansList;

              this.physiciansList = this.fnChangePhysicianNameFormat(
                apiResponsePhysician.commandResult.data.physiciansList
              );
            } else {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.demographics.findPryPhysiciansByCity
              );
              // alert("No Physicians Found");
              this.noPhysicianLength = true;
            }
          } else {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.findPryPhysiciansByCity
            );
            // alert("No Physicians Found");
            this.noPhysicianLength = true;
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }

  updatePhysicianInfo(physicianId) {
    this.physicianSelectDisabledOption = false;
    this.selectedPhysician = this.physiciansList.find(
      (e) => e.RecordId == physicianId
    );
    this.selectedPhysician.physicianEmailMasked = this.selectedPhysician
      .PhysicianEmail
      ? this.commonHelper.maskEmail(this.selectedPhysician.PhysicianEmail)
      : "";
    localStorage.setItem(
      "selectedHippaState",
      this.selectedPhysician.PhysicianState
    );
    localStorage.setItem(
      "selectedPhysicianCity",
      this.selectedPhysician.PhysicianCity
    );
    localStorage.setItem(
      "providerName",
      this.userService.getRefromatName(this.selectedPhysician.PhysicianName)
    );
    localStorage.setItem(
      "providerOfficeAddress",
      this.selectedPhysician.PhysicianOfficeAddress
    );
    localStorage.setItem(
      "providerZipCode",
      this.selectedPhysician.zip_code ? this.selectedPhysician.zip_code : ""
    );
    localStorage.setItem(
      "providerPhone",
      this.selectedPhysician.PhysicianOfficePhone
        ? this.selectedPhysician.PhysicianOfficePhone
        : ""
    );
    localStorage.setItem("providerEmail", this.selectedEmail);
    localStorage.setItem(
      "providerPhysicianName",
      this.userService.getRefromatName(this.selectedPhysician.PhysicianName)
    );
    localStorage.setItem(
      "providerPhysicianNipNo",
      this.selectedPhysician.PhysicianNipNo
    );
  }

  setStateCode(stateCode) {
    this.selectedStateCode = stateCode;
  }

  onChangeUserState(stateCode) {
    // alert(stateCode)
    this.userService.findHippaByStateCode(stateCode).subscribe(
      (apiResponse) => {
        this.resultJsonObj = apiResponse;
        if (this.resultJsonObj.commandResult.status == 1) {
          this.hippaAvailable = true;
          this.selectedDocUrl =
            this.resultJsonObj.commandResult.data.hippaDetails.DocumentUrl;
        } else {
          this.hippaAvailable = false;
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  fnChangePhysicianNameFormat(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.PhysicianName = this.userService.getUS_FromatName(e.PhysicianName);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(
      this.userService.dynamicSort("PhysicianName")
    );
  }

  closeInfoModal() {
    this.showInfoModal = false;
  }

  setRequestId(requestId) {
    this.selectedRequestId = requestId;
  }

  changeHippaRequestStatus(requestId) {
    this.selectedRequestId = requestId;
    const reqData = { requestId };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_changeHippaRequestStatusApi,
        reqData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {},
        (err) => {
          console.error(err);
        }
      );
  }

  closeComfirmationModal() {
    this.showComfirmationModal = false;
  }

  openSearchPhysicianModal() {
    this.showSearchPhysicianModal = true;
    this.physicianSearchObj.physicianSearchFirstName = "";
    this.physicianSearchObj.physicianSearchLastName = "";
    this.physicianSearchObj.physicianSearchState = "";
    this.physicianSearchObj.physicianSearchNPI = "";
    this.doctorTableArr = [];
  }

  resetSearchPhysicianModal() {
    this.physicianSearchObj.physicianSearchLastName = "";
    this.physicianSearchObj.physicianSearchFirstName = "";

    this.physicianSearchObj.physicianSearchState = "";
    this.physicianSearchObj.physicianSearchNPI = "";
    this.doctorTableArr = [];
    this.physicianCount = 1;
  }

  closeSearchPhysicianModal() {
    this.showSearchPhysicianModal = false;
    this.physicianCount = 1;
  }

  async searchPhysicians() {
    if (this.physicianSearchObj.physicianSearchNPI){  
      if(this.physicianSearchObj.physicianSearchNPI.length !== 10 || !/^\d+$/.test(this.physicianSearchObj.physicianSearchNPI)) {
           // Display an error message for invalid NPI length
           const alertStatus = await this.sweetAlertHelper.alertPopUp(
             this.message.alertMessages.demographics.invalidNPI
           );
           return;
         }}
    if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchFirstName
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchPhysicians
      );

      // alert(
      //   "Please enter first name. You may search physician informantion by NPI number."
      // );
      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchLastName
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchPhysician2
      );

      // alert(
      //   "Please enter last name. You may search physician informantion by NPI number."
      // );
      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchState
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.searchPhysicians1
      );

      // alert("Please select state.");
      return;
    } else {
      this.doctorTableArr = [];
      this.showLoader = true;
      const finalData = {
        loggedInUserId: this.loggedInUserId,
        physicianSearchFirstName:
          this.physicianSearchObj.physicianSearchFirstName,
        physicianSearchLastName:
          this.physicianSearchObj.physicianSearchLastName,
        physicianSearchState: this.physicianSearchObj.physicianSearchState,
        physicianSearchNPI: this.physicianSearchObj.physicianSearchNPI,
      };
      this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.searchNPIRegistry,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
             if (apiResponse.commandResult.data && apiResponse.commandResult.data.physiciansList.length > 0) {  //npi registry problem resolved
            this.doctorTableArr = apiResponse.commandResult.data.physiciansList;
            this.hippaAvailable = true;
            this.hasHippaFile = true;
            this.selectedDocUrl =
              apiResponse.commandResult.data.hippaDetails.DocumentUrl;
            this.physicianCount = apiResponse.commandResult.data.physiciansCout;
            this.showLoader = false;
          } else{
            // Records not found or no data received - handle accordingly
            this.doctorTableArr = [];
            this.hippaAvailable = false;
            this.hasHippaFile = false;
            this.selectedDocUrl = null;
            this.physicianCount = 0;

            // Display a message to the user indicating no records were found
            // alert('No records found for the entered NPI.');
            const alertStatus = this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.demographics.notValidNpi
            );
            this.showLoader = false;
            if(alertStatus){
              return false;
            }
          }
           this.showLoader = false;
        },
          (err) => {
            console.error(err);
            this.showLoader = false;
          }
        );
    }
  }

  checkEmail() {
    // Check if the alternate email matches the logged-in user's email
    if (this.selectedPhysician.altEmail && localStorage.getItem("loggedInUserEmail")) {
      if (this.selectedPhysician.altEmail.toLowerCase() === localStorage.getItem("loggedInUserEmail").toLowerCase()) {
        this.checkAltEmail = true;
      } else {
        this.checkAltEmail = false;
      }
    } else {
      this.checkAltEmail = false; // Reset to false if either email is not available
    }
  }

  selectDoctor(physicianId) {
    const tempAltEmail = this.selectedPhysician.altEmail
    const cnfAltEmail = this.selectedPhysician.cnfAltEmail
    this.selectedPhysicianId = physicianId;
    this.physicianSelectDisabledOption = false;
    this.isSearched = true;
    this.selectedPhysician = this.doctorTableArr.find(
      (e) => e.RecordId == physicianId
    );
    this.showSearchPhysicianModal = false;
    this.docInfo.state = this.selectedPhysician.PhysicianState;
    this.docInfo.city = this.selectedPhysician.PhysicianCity;
    this.selectedCityName = this.selectedPhysician.PhysicianCity;
    this.docInfo.physicianName = this.selectedPhysician.RecordId;
    this.docInfo.physician_Name = this.selectedPhysician.PhysicianName;
    this.selectedStateCode = this.selectedPhysician.PhysicianStateCode;
    this.selectedPhysician.physicianName = this.selectedPhysician.PhysicianName;
    this.selectedPhysician.physicianEmailMasked = this.selectedPhysician
      .PhysicianEmail
      ? this.commonHelper.maskEmail(this.selectedPhysician.PhysicianEmail)
      : "";
      this.selectedPhysician.altEmail = tempAltEmail
      this.selectedPhysician.cnfAltEmail = cnfAltEmail
    const finalData = { physicianNPI: this.selectedPhysician.PhysicianNipNo };
    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findPhysicianEmail,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.selectedEmail = apiResponse.commandResult.data.physicianEmail;
          this.selectedPhysician.PhysicianEmail = apiResponse.commandResult.data
            .physicianEmail
            ? apiResponse.commandResult.data.physicianEmail
            : "";
          this.selectedPhysician.physicianEmailMasked = apiResponse
            .commandResult.data.physicianEmail
            ? this.commonHelper.maskEmail(
                apiResponse.commandResult.data.physicianEmail
              )
            : "";

          this.hasPhysicianEmail =
            apiResponse.commandResult.data.hasPhysicianEmail;
          if (apiResponse.commandResult.data.hasPhysicianEmail == 1) {
            this.selectedPhysician.PhysicianEmail =
              apiResponse.commandResult.data.physicianEmail;
          } else {
            this.selectedPhysician.PhysicianEmail =
              apiResponse.commandResult.data.physicianEmail;
            this.showComfirmationModal = true;
          }
          localStorage.setItem(
            "selectedHippaState",
            this.selectedPhysician.PhysicianState
          );
          localStorage.setItem(
            "providerName",
            this.userService.getRefromatName(
              this.selectedPhysician.PhysicianName
            )
          );
          localStorage.setItem(
            "providerOfficeAddress",
            this.selectedPhysician.PhysicianOfficeAddress
          );
          localStorage.setItem(
            "providerZipCode",
            this.selectedPhysician.zip_code
              ? this.selectedPhysician.zip_code
              : ""
          );
          localStorage.setItem(
            "providerPhone",
            this.selectedPhysician.PhysicianOfficePhone
              ? this.selectedPhysician.PhysicianOfficePhone
              : ""
          );
          localStorage.setItem(
            "selectedCityName",
            this.selectedPhysician.PhysicianCity
          );
          localStorage.setItem(
            "selectedPhysicianState",
            this.selectedPhysician.PhysicianState
          );
          localStorage.setItem("providerEmail", this.selectedEmail);
          localStorage.setItem(
            "providerPhysicianName",
            this.userService.getRefromatName(
              this.selectedPhysician.PhysicianName
            )
          );
          localStorage.setItem(
            "providerPhysicianNipNo",
            this.selectedPhysician.PhysicianNipNo
          );
          this.onChangeUserState(this.selectedStateCode);

          if (
            this.selectedPhysician.PhysicianStateCode == "NJ" ||
            this.selectedPhysician.PhysicianStateCode == "NY" ||
            this.selectedPhysician.PhysicianStateCode == "FL" ||
            this.selectedPhysician.PhysicianStateCode == "PA" ||
            this.selectedPhysician.PhysicianStateCode == "CT" ||
            this.selectedPhysician.PhysicianStateCode == "OH" ||
            this.selectedPhysician.PhysicianStateCode == "CO" ||
            this.selectedPhysician.PhysicianStateCode == "CA" ||
            this.selectedPhysician.PhysicianStateCode == "AL" ||
            this.selectedPhysician.PhysicianStateCode == "AZ" ||
            this.selectedPhysician.PhysicianStateCode == "AR" ||
            this.selectedPhysician.PhysicianStateCode == "DE" ||
            this.selectedPhysician.PhysicianStateCode == "GA" ||
            this.selectedPhysician.PhysicianStateCode == "HI" ||
            this.selectedPhysician.PhysicianStateCode == "ID" ||
            this.selectedPhysician.PhysicianStateCode == "IL" ||
            this.selectedPhysician.PhysicianStateCode == "AK" ||
            this.selectedPhysician.PhysicianStateCode == "TX" ||
            this.selectedPhysician.PhysicianStateCode == "SD" ||
            this.selectedPhysician.PhysicianStateCode == "ND" ||
            this.selectedPhysician.PhysicianStateCode == "VA" ||
            this.selectedPhysician.PhysicianStateCode == "VT" ||
            this.selectedPhysician.PhysicianStateCode == "KY" ||
            this.selectedPhysician.PhysicianStateCode == "WI" ||
            this.selectedPhysician.PhysicianStateCode == "WV" ||
            this.selectedPhysician.PhysicianStateCode == "TN" ||
            this.selectedPhysician.PhysicianStateCode == "LA" ||
            this.selectedPhysician.PhysicianStateCode == "MD" ||
            this.selectedPhysician.PhysicianStateCode == "MN" ||
            this.selectedPhysician.PhysicianStateCode == "MO" ||
            this.selectedPhysician.PhysicianStateCode == "MS" ||
            this.selectedPhysician.PhysicianStateCode == "NV" ||
            this.selectedPhysician.PhysicianStateCode == "OK" ||
            this.selectedPhysician.PhysicianStateCode == "WA" ||
            this.selectedPhysician.PhysicianStateCode == "IA" ||
            this.selectedPhysician.PhysicianStateCode == "NH" ||
            this.selectedPhysician.PhysicianStateCode == "KS" ||
            this.selectedPhysician.PhysicianStateCode == "OR" ||
            this.selectedPhysician.PhysicianStateCode == "NE" ||
            this.selectedPhysician.PhysicianStateCode == "MT" ||
            this.selectedPhysician.PhysicianStateCode == "NC" ||
            this.selectedPhysician.PhysicianStateCode == "SC" ||
            this.selectedPhysician.PhysicianStateCode == "IN" ||
            this.selectedPhysician.PhysicianStateCode == "ME" ||
            this.selectedPhysician.PhysicianStateCode == "RI" ||
            this.selectedPhysician.PhysicianStateCode == "UT" ||
            this.selectedPhysician.PhysicianStateCode == "NM" ||
            this.selectedPhysician.PhysicianStateCode == "MA"
          ) {
            this.isProgrammed = true;
          } else {
            this.isProgrammed = false;
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }

  validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }

  clearSearchPhysicianInputs(flag){
    if(flag){
      this.physicianSearchObj.physicianSearchFirstName = '';
      this.physicianSearchObj.physicianSearchLastName = '';
      this.physicianSearchObj.physicianSearchState = '';
    }else{
      this.physicianSearchObj.physicianSearchNPI = '';
    }
  }
  isButtonDisabled(): boolean {
    this.checkEmail();
    return this.checkAltEmail;
  }
  
}
