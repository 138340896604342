import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import {environment} from './../../../environments/environment';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {AppConfig} from './../../../app/app.config';
import {UserService} from './../../_services/user.service';
import {SweetAlertHelper} from '../../_helpers/sweet.alert.helper';
import {Message} from '../../_locale/messages';

@Component({
  selector: 'app-email-log',
  templateUrl: './email-log.component.html',
  styleUrls: ['./email-log.component.scss'],
})
export class EmailLogComponent implements OnInit {
  loggedInUserId: string;
  comapnyName: string;
  logsList: any = [];
  companySlug: any;

  doctorLength = false;
  doctorList: any;
  showLoader = false;
  regDoctorInfo: any;
  actualLoggedInUserId = '';

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  onBehalfOfAlertSendDocumentLog = 'Y';
  message = Message;
  dtOptions: DataTables.Settings = {};

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService
  ) {
    this.actualLoggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.loggedInUserId = localStorage.getItem('delegateId') ? localStorage.getItem('delegateId') : this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.regDoctorInfo = this.activatedRoute.snapshot.paramMap.get('regDoctorInfo') ? this.activatedRoute.snapshot.paramMap.get('regDoctorInfo') : 'all';
  }

  async ngAfterViewInit() {
    var item = localStorage.getItem('FullName');
    if (
      this.onBehalfOfAlertSendDocumentLog === 'Y' &&
      localStorage.getItem('isDelegating') === '1'
    ) {
      // alert(
      //   "Dear User, you are doing this activity on behalf of " +
      //     localStorage.getItem("FullName")
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.demographics.ngAfterViewInit + ' ' + item
      );

      localStorage.setItem('onBehalfOfAlertSendDocumentLog', 'N');
    }
  }

  ngOnInit() {
    this.findUserDoctors();
    this.fnFindPatientEmailLog(this.regDoctorInfo);


    this.onBehalfOfAlertSendDocumentLog = localStorage.getItem(
      'onBehalfOfAlertSendDocumentLog'
    )
      ? localStorage.getItem('onBehalfOfAlertSendDocumentLog')
      : 'Y';
    window.scroll(0, 0);
    this.dtOptions = {
      order: [],
    };
  }

  findUserDoctors() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findUserDoctorsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.showLoader = false;
          if (apiResponse.commandResult.data.companiesList.length > 0) {
            this.doctorLength = true;
            this.doctorList = apiResponse.commandResult.data.companiesList;
          } else {
            this.doctorLength = false;
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }

  fnFindEmailLogs() {
    this.logsList = [];
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: null,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_esignEmailLogApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.showLoader = false;
          if (apiResponse.commandResult.data.logsList.length > 0) {
            this.logsList = apiResponse.commandResult.data.logsList;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindLog(doctorId) {
    // debugger
    if (doctorId == 'esign') {
      this.fnFindEmailLogs();
    } else {
      this.regDoctorInfo = doctorId;
      this.fnFindAdminEmailLogs(doctorId);
    }
  }

  fnFindAdminEmailLogs(adminId) {
    this.logsList = [];
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      adminUserId: adminId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getAdminEmailLogs,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.showLoader = false;
          if (apiResponse.commandResult.data.logsList.length > 0) {
            this.logsList = apiResponse.commandResult.data.logsList;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async sendDocs(logId) {
    // if (confirm("PLEASE CONFIRM: Are you sure?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.emailLogComp.sendDocs
    );
    if (alertStatus) {
      this.router.navigate([
        '/resend-docs/' +
        this.actualLoggedInUserId +
        '/' +
        logId +
        '/' +
        this.regDoctorInfo,
      ]);
    }
  }

  async goToDashboad() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.ushfComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(['/dashboard/' + this.actualLoggedInUserId]);
    }
  }

  onChangeSetRegDoctor(value) {
    this.companySlug = value;
    // console.log(this.companySlug)
  }


  fnFindPatientEmailLog(adminId) {
    this.logsList = [];
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      adminUserId: adminId,
    };

    console.log(finalData);

    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getPatientEmailLogs,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.showLoader = false;
          if (apiResponse.commandResult.data.logsList.length > 0) {
            this.logsList = apiResponse.commandResult.data.logsList;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }


  async resendEmail(logId) {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.emailLogComp.resendDocs);
    if (alertStatus) {
      const finalData = {logId, loggedInUserId: this.loggedInUserId};
      this.showLoader = true;
      this.http.post(this.appConfig.getLambdaUrl06() + ApiHelper.resendEmailLog, finalData, this.httpOptions)
        .subscribe(async (apiResponse: any) => {
            this.showLoader = false;
            await this.sweetAlertHelper.alertPopUp('Email is successfully sent to physician.');
            window.location.reload();
          },
          (err) => {
            this.showLoader = false;
            console.error(err);
          });
    }
  }


}
