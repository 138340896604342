import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { SignaturePad } from "angular2-signaturepad";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { SweetAlertHelper } from "../../_helpers/sweet.alert.helper";
import { Message } from "../../_locale/messages";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-view-docs-acknowledgement",
  templateUrl: "./view-docs-acknowledgement.component.html",
  styleUrls: ["./view-docs-acknowledgement.component.scss"],
})
export class ViewDocsAcknowledgementComponent implements OnInit {
  pId: string;
  loggedInUserId: string;
  logId: string;
  userOptionId = "0";
  showLoader = false;

  userId: string;
  patientName: string;
  loggedInUserObj: any = {};
  patientObj: any = {};
  imageObj: any = {};
  imageUrl: string;
  hasAttachment = 0;
  userChoosedSignature: any;
  ahsPdfUrl: SafeResourceUrl = "";
  showPdfModal = false;
  showThankUModal = false;
  showNoMatchUModal = false;
  showCancelModal = false;
  hippaAttachment: any = {};

  resultJsonObj: any = {};
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
  canvasElm: any;
  // tslint:disable-next-line: ban-types
  public signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    minWidth: 1,
    canvasWidth:
      window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
    canvasHeight: 450,
  };

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public sweetAlertHelper: SweetAlertHelper,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    // console.log(this.userOptionId)
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.pId = this.activatedRoute.snapshot.paramMap.get("pId");
    this.logId = this.activatedRoute.snapshot.paramMap.get("logId")
      ? this.activatedRoute.snapshot.paramMap.get("logId")
      : "0";
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.userOptionId = this.activatedRoute.snapshot.paramMap.get(
      "userOptionId"
    )
      ? this.activatedRoute.snapshot.paramMap.get("userOptionId")
      : "0";
    if (this.userOptionId == "0") {
      this.findPatientUserInfo(this.pId);
    } else {
      this.findPatientUserInfoByLogID(this.logId);
    }
  }

  ngAfterViewInit() {
    this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }
  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }
  async clearCanvas() {
    // if (confirm('PLEASE CONFIRM: Are you sure you want to clear you signature.'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.clearCanvas
    );
    if (alertStatus) {
      this.signaturePad.clear();
    }
  }
  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log("begin drawing");
  }
  findPatientUserInfo(patientUserHashId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      profileId: patientUserHashId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findUserDetailsByUserHashApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log('hash user', apiResponse);
          this.patientObj = apiResponse;
          // console.log('patienthash', apiResponse.commandResult.data.UserInfo);
          this.patientName = apiResponse.commandResult.data.UserInfo.FullName;
          this.userId = apiResponse.commandResult.data.UserInfo.RecordId;
          this.userChoosedSignature =
            apiResponse.commandResult.data.UserInfo.Signature;
          this.hasAttachment =
            apiResponse.commandResult.data.UserInfo.HasAttachment;
          this.hippaAttachment = apiResponse.commandResult.data.UserInfo.Hippa;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPatientUserInfoByLogID(logId) {
    const finalData = {
      logId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findUserDetailsByD2DId,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log('hash user', apiResponse);
          this.patientObj = apiResponse;
          this.patientName = apiResponse.commandResult.data.UserInfo.FullName;
          this.userId = apiResponse.commandResult.data.UserInfo.RecordId;
          this.userChoosedSignature =
            apiResponse.commandResult.data.UserInfo.Signature;
          this.hasAttachment =
            apiResponse.commandResult.data.UserInfo.HasAttachment;
          this.hippaAttachment = apiResponse.commandResult.data.UserInfo.Hippa;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  saveCanvas() {
    if (this.userOptionId == "0") {
      this.normalSign();
    } else {
      this.d2dSign();
    }
  }

  async normalSign() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: By clicking 'OK', you are signing the document and now will be able to review each."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.viewDocsAcknowComp.normalSign
    );

    if (alertStatus) {
      const finalData = {
        pId: this.pId,
        userId: this.loggedInUserId,
        signatureBase64: this.signaturePad.toDataURL(),
      };
      this.showLoader = true;

      return this.http
        .post(
          this.appConfig.getLambdaUrl05() +
            ApiHelper.name_generateViewDocSignatureImageApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            this.imageObj = apiResponse;
            this.imageUrl =
              apiResponse.commandResult.data.fileInfo.signatureUrl;
            // console.log('imageUrl', this.imageUrl);
            this.http
              .post(
                this.appConfig.getPhpUrl() +
                  ApiHelper.php_generateViewDocSignatureFilePdfApi,
                finalData,
                this.httpOptions
              )
              .subscribe(
                async (_apiResponse: any) => {
                  // console.log(_apiResponse);
                  // alert("Acknowledgement sent to the patient.");
                  const alertStatus = await this.sweetAlertHelper.alertPopUp(
                    this.message.alertMessages.viewDocKnowComp.normalSign
                  );

                  if (
                    this.userOptionId == "1" ||
                    this.userOptionId == "3" ||
                    this.userOptionId == "6"
                  ) {
                    // tslint:disable-next-line: max-line-length
                    this.router.navigate([
                      "/doctor/user-docs/" +
                        this.pId +
                        "/" +
                        this.logId +
                        "/" +
                        this.loggedInUserId +
                        "/" +
                        this.userOptionId,
                    ]);
                  } else {
                    this.router.navigate([
                      "/doctor/user-docs/" +
                        this.pId +
                        "/" +
                        this.loggedInUserId,
                    ]);
                  }
                  this.showLoader = false;
                },
                (err) => {
                  // console.log(err);
                }
              );
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async d2dSign() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: By clicking 'OK', you are signing the document and now will be able to review each."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.viewDocsAcknowComp.normalSign
    );

    if (alertStatus) {
      const finalData = {
        pId: this.pId,
        userId: this.loggedInUserId,
        logId: this.logId,
        signatureBase64: this.signaturePad.toDataURL(),
      };
      this.showLoader = true;

      return this.http
        .post(
          this.appConfig.getLambdaUrl05() +
            ApiHelper.name_generateViewDocSignatureImageApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            this.imageObj = apiResponse;
            this.imageUrl =
              apiResponse.commandResult.data.fileInfo.signatureUrl;
            // console.log('imageUrl', this.imageUrl);
            this.http
              .post(
                this.appConfig.getLambdaUrl06() +
                  ApiHelper.generateD2DDocSignatureFilePdf,
                finalData,
                this.httpOptions
              )
              .subscribe(
                async (_apiResponse: any) => {
                  // console.log(_apiResponse);
                  // alert("Acknowledgement sent to the patient.");
                  const alertStatus = await this.sweetAlertHelper.alertPopUp(
                    this.message.alertMessages.viewDocKnowComp.normalSign
                  );
                  if (
                    this.userOptionId == "1" ||
                    this.userOptionId == "3" ||
                    this.userOptionId == "6"
                  ) {
                    // tslint:disable-next-line: max-line-length
                    this.router.navigate([
                      "/doctor/user-docs/" +
                        this.pId +
                        "/" +
                        this.logId +
                        "/" +
                        this.loggedInUserId +
                        "/" +
                        this.userOptionId,
                    ]);
                  } else {
                    this.router.navigate([
                      "/doctor/user-docs/" +
                        this.pId +
                        "/" +
                        this.loggedInUserId,
                    ]);
                  }
                  this.showLoader = false;
                },
                (err) => {
                  // console.log(err);
                }
              );
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  closePdfModal() {
    this.showPdfModal = false;
  }

  loadDocunent(docId, docUrl, docName) {
    this.showPdfModal = true;
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(docUrl);
  }

  confirmHippa() {
    this.showPdfModal = false;
    this.showThankUModal = true;
  }

  closesThankUModal() {
    this.showThankUModal = false;
  }

  noMatchHippa() {
    this.showPdfModal = false;
    this.showNoMatchUModal = true;
  }

  closesNoMatchUModal() {
    this.showNoMatchUModal = false;
    const finalData = {
      patientHash: this.pId,
      doctorId: this.loggedInUserId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_sendHippaNoMatchMailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (_apiResponse: any) => {
          // console.log(_apiResponse);
          // this.router.navigate(['/showMail/' + this.pId + '/' + this.patientObj.commandResult.data.UserInfo.PhysicianEmail]);
          this.router.navigate(["/"]);
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  openCancelModal() {
    this.showCancelModal = true;
  }

  closesCancelModal() {
    this.showCancelModal = false;
  }

  logoutDoctor() {
    // this.router.navigate(['/showMail/' + this.pId + '/' + this.patientObj.commandResult.data.UserInfo.PhysicianEmail]);
    this.router.navigate(["/"]);
  }

  closeWindows() {
    // this.router.navigate(['/showMail/' + this.pId + '/' + this.patientObj.commandResult.data.UserInfo.PhysicianEmail]);
    this.router.navigate(["/"]);
  }
}
